import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
  Button,
} from "@bleu/ui";
import { SubmitButton } from "@components/SubmitButton";
import { cn } from "@utils/mergeClassNames";
import React, { useState } from "react";
import { useWizard } from "react-use-wizard";

export const Step = ({
  children,
  form,
  onNext = () => {},
  onPrevious = () => {},
  handleSubmit,
  mode,
}) => {
  const { nextStep, previousStep, isFirstStep, isLastStep } = useWizard();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const handleNext = async () => {
    const isValid = await form.trigger();
    if (!isValid) return;
    onNext();
    nextStep();
  };

  const handlePrevious = () => {
    onPrevious();
    previousStep();
  };

  const achievementCollectionAddress = form.getValues(
    "achievement_collection_address",
  );
  const rewardHasNftColletion =
    mode === "update" &&
    achievementCollectionAddress !== undefined &&
    achievementCollectionAddress !== null;

  return (
    <div className="flex size-full flex-col border-l dark:border-l-2">
      <div className="">{children}</div>
      <div className="mt-auto flex justify-between items-end space-x-2 gap-3 m-4">
        <Button
          variant="outline"
          className="text-sm"
          type="button"
          onClick={() => handlePrevious()}
          disabled={isFirstStep}
        >
          Previous
        </Button>

        {isLastStep ? (
          rewardHasNftColletion ? (
            <AlertDialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
              <AlertDialogTrigger asChild>
                <SubmitButton type="button">Save</SubmitButton>
              </AlertDialogTrigger>
              <AlertDialogContent>
                <AlertDialogHeader>
                  <AlertDialogTitle>Are you sure?</AlertDialogTitle>
                  <AlertDialogDescription>
                    This changes will be refleted in future minted NFTs.
                  </AlertDialogDescription>
                </AlertDialogHeader>
                <AlertDialogFooter>
                  <AlertDialogCancel>Cancel</AlertDialogCancel>
                  <SubmitButton type="button" onClick={handleSubmit}>
                    Save
                  </SubmitButton>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialog>
          ) : (
            <SubmitButton type="button" onClick={handleSubmit}>
              Save
            </SubmitButton>
          )
        ) : (
          <div className="flex gap-2">
            <SubmitButton
              type="button"
              onClick={handleSubmit}
              variant="secondary"
              className={cn({ hidden: mode !== "update" })}
            >
              Save
            </SubmitButton>
            <Button
              type="button"
              className="text-sm ml-auto"
              onClick={() => handleNext()}
              disabled={isLastStep}
            >
              Next
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};
