import { SWRDataTable } from "@bleu/ui";
import React from "react";
import { useParams } from "react-router-dom";

function ParticipantAttributesTable() {
  const { end_user_id } = useParams();

  return (
    <SWRDataTable
      fetchPath={`/admin/v2/participants/${end_user_id}/participant_profile`}
      action={null}
      searchKey="attribute_display_name"
    />
  );
}

export default ParticipantAttributesTable;
