import React from "react";

import { ChallengeData } from "./types";

export function FooterButton({
  data,
  completedCondition,
  handleSubmit,
}: {
  data: ChallengeData;
  completedCondition: boolean;
  handleSubmit?: () => void;
}) {
  async function onClick() {
    if (handleSubmit && !completedCondition) {
      await handleSubmit();
    }
    if (!completedCondition && data?.link_url) {
      window.open(data.link_url, "_blank");
    } else if (completedCondition) {
      window.location.href = "/";
    }
  }
  return (
    <button className="custom_btn zoom_btn" onClick={onClick}>
      {completedCondition ? "Earn More" : data.cta_button_text}
    </button>
  );
}
