import {
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  SubmitButton,
} from "@bleu/ui";
import Card from "@components/ui/Card";
import { ArrowRightIcon, Cross1Icon } from "@radix-ui/react-icons";
import React from "react";

export function EnterForm({ program, form, handleSubmit }) {
  return (
    <Card.Root className="w-full max-w-none bg-white border pb-6 !rounded-perk-cards">
      <Card.Header>
        <div className="flex w-full justify-end">
          <Button
            className="bg-transparent shadow-none w-fit p-2 outline-perk-primary"
            onClick={() => {
              window.location.href = "/";
            }}
          >
            <Cross1Icon className="size-3" />
          </Button>
        </div>
        <Card.Title className="pl-0 text-xl text-center">
          Welcome to {program?.name}!
        </Card.Title>
      </Card.Header>

      <Card.Content className="mx-4">
        <Form {...form} onSubmit={form.handleSubmit(handleSubmit)}>
          <div className="grid gap-2">
            <div className="grid gap-1">
              <FormField
                control={form.control}
                name="email"
                render={({ field }) => (
                  <FormItem className="w-full">
                    <FormLabel>Please enter your email to continue.</FormLabel>
                    <FormControl>
                      <Input
                        className="placeholder:text-gray-400 rounded-perk-input"
                        autoFocus
                        placeholder="your email"
                        id="email"
                        type="email"
                        required
                        autoCapitalize="none"
                        autoComplete="email"
                        autoCorrect="off"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage className="text-xs" />
                  </FormItem>
                )}
              />
            </div>
            <SubmitButton
              type="submit"
              className="bg-perk-primary text-perk-primary-content hover:bg-perk-primary/90 rounded-perk-button"
            >
              Next <ArrowRightIcon className="ml-2 size-3" />
            </SubmitButton>
          </div>
        </Form>
      </Card.Content>
    </Card.Root>
  );
}
