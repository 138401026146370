import { formatNumber, i18n, KpiCard } from "@bleu/ui";
import {
  CheckCircledIcon,
  EyeOpenIcon,
  LightningBoltIcon,
  PersonIcon,
} from "@radix-ui/react-icons";
import React from "react";

export function DashboardKpi({ programPerformanceMetrics }) {
  const kpiData = [
    {
      title: "Total Active Users",
      total: programPerformanceMetrics?.total_active_participants,
      recent: programPerformanceMetrics?.recent_active_participants,
      icon: <PersonIcon className="text-gold size-6" />,
    },
    {
      title: "Challenges Completed",
      total: programPerformanceMetrics?.total_completed_challenges,
      recent: programPerformanceMetrics?.recent_completed_challenges,
      icon: <CheckCircledIcon className="text-gold size-6" />,
    },
    {
      title: "Program Traffic",
      total: programPerformanceMetrics?.total_program_visits,
      recent: programPerformanceMetrics?.recent_program_visits,
      icon: <EyeOpenIcon className="text-gold size-6" />,
    },
    {
      title: "Total Actions",
      total: programPerformanceMetrics?.total_participant_actions,
      recent: programPerformanceMetrics?.recent_participant_actions,
      icon: <LightningBoltIcon className="text-gold size-6" />,
    },
  ];

  return (
    <div className="flex gap-x-2">
      {kpiData.map((kpi) => {
        return (
          <KpiCard key={kpi.title}>
            <KpiCard.Header>
              <KpiCard.Title>{kpi.title}</KpiCard.Title>
              {kpi.icon}
            </KpiCard.Header>
            <KpiCard.Content className="text-3xl">
              {formatNumber(
                kpi.total,
                0,
                "decimal",
                "standard",
                0.001,
                i18n.default.language,
              )}
            </KpiCard.Content>
            {kpi.recent > 0 && (
              <KpiCard.FooterNote>
                + {formatNumber(kpi.recent, 0, "decimal", "standard")} from last
                thirty days
              </KpiCard.FooterNote>
            )}
          </KpiCard>
        );
      })}
    </div>
  );
}
