import React, { useEffect, useRef } from "react";
import { SocialIcon } from "react-social-icons";

const PostDisplay = ({ post }) => {
  const videoRef = useRef(null);

  useEffect(() => {
    const video = videoRef.current;
    if (post.post_type === "video" && video) {
      video.onloadedmetadata = () => {
        if (video.paused) {
          video.play().catch(() => {
            // Auto-play was prevented. Mute and play.
            video.muted = true;
            video.play().catch(() => {
              // Auto-play was prevented. Show a "Play"
              // button so that user can start playback.
              video.controls = true;
            });
          });
        }
      };
    }
  }, [post]);

  if (post.post_type === "image") {
    return (
      <img
        src={post.media_path}
        loading="eager"
        className="size-full rounded-lg object-cover"
      />
    );
  }

  if (post.post_type === "video") {
    return (
      <div className="size-full rounded-lg bg-[#383838] object-cover">
        <video
          ref={videoRef}
          className="size-full rounded-lg object-cover"
          autoPlay
          playsInline
          muted
        >
          <source src={post.media_path} />
          <p>This browser does not support the video element.</p>
        </video>
      </div>
    );
  }

  return (
    <div className="flex size-full items-center justify-center bg-gray-100 p-3">
      <p className="line-clamp-6 text-left text-lg text-gray-800">
        {post.message}
      </p>
    </div>
  );
};

const Post = ({ post, challenge_id }) => {
  const user =
    post.username || `@${post.social_network_page?.url?.split("/").pop()}`;
  return (
    <div className="relative h-48 w-40 sm:size-60">
      <a href={`/challenge/${challenge_id}?post_id=${post.id}`}>
        <PostDisplay post={post} />
      </a>
      <div className="absolute bottom-0 flex w-full justify-between bg-gradient-to-t from-black/80 to-transparent px-2 pb-1 pt-3">
        <div className="flex w-full items-center justify-start gap-1">
          <SocialIcon
            network={post.social_network_page.platform}
            style={{ height: 18, width: 18 }}
            bgColor="#ffffff"
            href=""
          />
          <h2 className="m-0 p-0 text-sm text-white">{user}</h2>
        </div>
      </div>
    </div>
  );
};

export default function SocialFeedBanner({ challenge, posts }) {
  return (
    <div className="flex h-fit min-w-full flex-col items-center bg-gradient-to-b from-[#313131] to-black py-5 sm:flex-row">
      <div className="flex size-full flex-col items-center gap-5 sm:flex-row md:px-28">
        <div className="flex h-2/3 w-full min-w-80 flex-col justify-center gap-2 px-3 sm:w-1/4">
          <h1 className="text-4xl font-black text-white">Get Social</h1>
          <p className="text-xl text-white">
            Click a post to view and earn. Check back daily to stay up to date
            and earn more{" "}
          </p>

          <a href={`/challenge/${challenge.identifier}`}>
            <div className="text-perk-primary-content bg-perk-primary w-3/5 rounded-full px-2 py-1 text-center">
              VIEW POSTS +{challenge.points}/EACH
            </div>
          </a>
        </div>
        <div className="mt-4 grid w-full grid-cols-2 items-center justify-center gap-5 px-2 sm:mt-0 sm:w-3/4 sm:grid-cols-3">
          {posts &&
            posts.map((post) => {
              return (
                <Post
                  key={post.id}
                  post={post}
                  challenge_id={challenge.identifier}
                />
              );
            })}
        </div>
      </div>
    </div>
  );
}
