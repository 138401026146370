import { SWRDataTable } from "@bleu/ui";
import { DownloadButton } from "@components/DownloadButton";
import { useQueryAppendedUrl } from "@hooks/useQueryAppendedUrl";
import React from "react";
import { useParams } from "react-router-dom";

function ChallengeAnswersTable() {
  const { challenge_id } = useParams();

  return (
    <SWRDataTable
      fetchPath={`/admin/v2/challenges/${challenge_id}/participant_answers`}
    />
  );
}

export default ChallengeAnswersTable;
