import { KpiCard } from "@bleu/ui";
import * as React from "react";

export function SimpleKpiCard({
  title,
  children,
}: React.PropsWithChildren<{
  title: string;
}>) {
  return (
    <KpiCard>
      <KpiCard.Header>
        <KpiCard.Title>{title}</KpiCard.Title>
      </KpiCard.Header>
      <KpiCard.Content>{children}</KpiCard.Content>
    </KpiCard>
  );
}
