import { adminV2Users } from "@api/index";
import { SectionTitle, SWRDataTable, toast } from "@bleu/ui";
import { useUser } from "@contexts/UserContext";
import { AddAdminDialog } from "@pages/admin/(components)/InviteAdmin";
import React from "react";
import { json, redirect, useLoaderData, useLocation } from "react-router-dom";

const loader = async () => {
  const data = await adminV2Users.new();
  return json({ data });
};

const action = async ({ request }) => {
  const body = await request.json();

  const data = await adminV2Users.create({
    data: { ...body.data },
  });

  if (data?.success) {
    toast({
      title: "Done!",
      description: "The admin has been invited.",
    });
    return redirect(`/admin/v2/organization/admins`);
  } else {
    toast({
      title: "An error occurred.",
      description: data.errors,
      variant: "destructive",
    });
    return json({ success: false });
  }
};

function OrganizationAdminsPage() {
  const { hash } = useLocation();
  const { isAdmin } = useUser();
  // @ts-expect-error TS(2339) FIXME: Property 'data' does not exist on type '{}'.
  const { data } = useLoaderData();

  const renderAction = () => {
    if (!isAdmin) {
      return null;
    }

    return (
      <AddAdminDialog
        defaultOpen={hash === "#invite"}
        description="Add a new admin to your organization."
        fields={data?.data?.fields}
      />
    );
  };

  return (
    <>
      <div className="flex items-center justify-between space-y-2">
        <div>
          <SectionTitle>Admins</SectionTitle>
          <p className="text-muted-foreground">
            Here&apos;s a list of the organization admins.
          </p>
        </div>
      </div>
      <SWRDataTable fetchPath="/admin/v2/users" action={renderAction()} />
    </>
  );
}

OrganizationAdminsPage.loader = loader;
OrganizationAdminsPage.action = action;
export default OrganizationAdminsPage;
