import { BaseField, buildForm, Button, Form } from "@bleu/ui";
import Card from "@components/ui/Card";
import Dialog from "@components/ui/Dialog";
import { PlusIcon } from "@radix-ui/react-icons";
import React from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";

export function AddCouponsForm() {
  const { reward_id } = useParams();
  const form = useForm({});

  const FIELDS = [
    {
      type: "textarea",
      name: "codes",
      label: "Codes",
      description:
        "Enter the coupon codes separated by a comma. Example: code1,code2,code3",
      required: true,
    },
    {
      type: "hidden",
      name: "reward_id",
      value: reward_id,
    },
  ] as BaseField[];

  return (
    <Card.Root className="w-full max-w-none bg-background">
      <Card.Header>
        <Card.Title className="pl-2 text-xl">Add Coupons</Card.Title>
        <Card.Description className="py-0 text-sm">
          Add coupons to your reward.
        </Card.Description>
      </Card.Header>
      <Card.Content className="mx-4">
        <Form
          action={`/admin/v2/rewards/${reward_id}/coupons`}
          method="post"
          className="space-y-8"
          {...form}
        >
          {buildForm(FIELDS, form)}
          <Button type="submit">Save</Button>
        </Form>
      </Card.Content>
    </Card.Root>
  );
}

export function AddCouponsDialog() {
  return (
    //  @ts-expect-error TS(2322) FIXME: Type '{ children: Element; className: string; }' i... Remove this comment to see the full error message
    <Dialog.Root>
      <Dialog.Trigger asChild>
        <Button size="sm" className="ml-auto hidden h-8 lg:flex text-sm">
          <PlusIcon className="mr-2 size-4" />
          Add Coupons
        </Button>
      </Dialog.Trigger>
      <Dialog.Content className="sm:max-w-[550px]">
        <AddCouponsForm />
      </Dialog.Content>
    </Dialog.Root>
  );
}
