import { adminV2Programs } from "@api/index";
import { Button, Form, toast } from "@bleu/ui";
import { SubmitButton } from "@components/SubmitButton";
import { useProgram } from "@contexts/ProgramContext";
import { SidebarNav } from "@pages/admin/(components)/SidebarNav";
import { client } from "@utils/api-client";
import { getObjectsDifference } from "@utils/objectDifference";
import { serialize } from "object-to-formdata";
import React from "react";
import { useForm, UseFormReturn } from "react-hook-form";
import {
  json,
  Outlet,
  redirect,
  useLoaderData,
  useParams,
  useSubmit,
} from "react-router-dom";

import { Preview } from "./Preview";

const loader = async ({ params }) => {
  const { program_id } = params;
  const { data } = await adminV2Programs.fonts({ id: program_id });
  return json(data);
};

const action = async ({ request, params }) => {
  const { program_id } = params;
  const formData = await request.formData();

  const path = adminV2Programs.updateTemplateDetails.path({ id: program_id });
  const data = await client(path, {
    body: formData,
    method: "PUT",
  });

  if (data?.success) {
    toast({
      title: "Done!",
      description: "The program has been updated.",
    });
    location.reload();
    return redirect(`/admin/v2/programs/${program_id}/settings/branding`);
  } else {
    toast({
      title: "An error occurred.",
      description: data.errors,
      variant: "destructive",
    });
    return json({ success: false });
  }
};

const brandingSidebar = (program_id) => {
  return [
    {
      title: "Branding Settings",
      items: [
        {
          title: "Colors",
          href: `/admin/v2/programs/${program_id}/settings/branding/colors`,
        },
        {
          title: "Fonts",
          href: `/admin/v2/programs/${program_id}/settings/branding/fonts`,
        },
        {
          title: "Borders",
          href: `/admin/v2/programs/${program_id}/settings/branding/borders`,
        },
        {
          title: "Assets",
          href: `/admin/v2/programs/${program_id}/settings/branding/assets`,
        },
        {
          title: "Theme",
          href: `/admin/v2/programs/${program_id}/settings/branding/theme`,
          label: "Coming Soon",
        },
        {
          title: "Custom CSS",
          href: `/admin/v2/programs/${program_id}/settings/branding/css`,
        },
      ],
    },
  ];
};

const isEmptyObject = (obj) => {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
};

const defaultValues = (template, element_css_style) => {
  return {
    element_css_style,
    favicon_file: template?.favicon_file?.url,
    header_logo: template?.header_logo?.url,
    header_background_image: template?.header_background_image?.url,
    header_text: template?.header_text,
    header_description: template?.header_description,
  };
};

type Fonts = {
  label: string;
  value: string;
  url: string;
}[];

export type BrandingOutletContextType = {
  form: UseFormReturn;
  fonts: Fonts;
};

function ProgramBrandingLayout() {
  const fonts = useLoaderData() as Fonts;
  const submit = useSubmit();
  const { program_id } = useParams();
  const { program_template_detail } = useProgram();

  const element_css_style = program_template_detail?.element_css_style || {};

  const form = useForm({
    defaultValues: defaultValues(program_template_detail, element_css_style),
  });

  const handleSubmit = async () => {
    const values = getObjectsDifference(
      defaultValues(program_template_detail, element_css_style),
      form.getValues(),
    );

    if (
      isEmptyObject(values) ||
      (Object.keys(values).length === 1 &&
        "element_css_style" in values &&
        isEmptyObject(values.element_css_style))
    ) {
      toast({
        title: "No changes detected.",
        description: "Please update the form to save changes.",
        variant: "destructive",
      });
      return;
    }

    const formData = serialize(values);
    submit(formData, { method: "post", encType: "multipart/form-data" });
  };

  const sidebarItems = brandingSidebar(program_id);

  return (
    <div className="container w-full flex-1 items-start md:grid md:grid-cols-[220px_minmax(0,1fr)] md:gap-6 lg:grid-cols-[190px_minmax(0,1fr)] lg:gap-10">
      <aside className="z-30 -ml-2 hidden shrink-0 md:sticky md:block h-[calc(100vh-4rem)]">
        {" "}
        {/* 100vh - header height */}
        <SidebarNav items={sidebarItems} />
      </aside>
      <div className="mb-8 size-full flex space-y-8 px-2 py-0 gap-5">
        <Form method="post" action={"/"} className="space-y-8 w-1/2" {...form}>
          <Outlet context={{ form, fonts }} />
          <div className="flex gap-2">
            <Button
              className="text-sm"
              type="button"
              variant="outline"
              onClick={() => form.reset()}
            >
              Reset
            </Button>
            <SubmitButton onClick={handleSubmit} type="button">
              Save
            </SubmitButton>
          </div>
        </Form>
        <div className="flex w-1/2 justify-center">
          <Preview form={form} fonts={fonts} />
        </div>
      </div>
    </div>
  );
}

ProgramBrandingLayout.loader = loader;
ProgramBrandingLayout.action = action;
export default ProgramBrandingLayout;
