import {
  Checkbox,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from "@bleu/ui";
import React from "react";

export const ImageMultiSelectCheckboxesField = ({ field, form }) => {
  return (
    <FormField
      control={form.control}
      name={field.name}
      rules={field.required ? { required: true } : undefined}
      render={({ field: formField }) => (
        <FormItem className="space-y-0 w-full">
          <FormLabel tooltip={field.tooltip}>{field.label}</FormLabel>
          {field.description && (
            <p className="text-sm text-gray-500">{field.description}</p>
          )}
          <FormControl className="w-full">
            <div className="flex gap-x-2">
              {field.options.map((option) => (
                <FormField
                  key={option.value}
                  control={form.control}
                  name={field.name}
                  render={({ field: checkboxField }) => (
                    <FormItem className="relative flex flex-col w-full">
                      <FormControl>
                        <div className="flex flex-col items-center grow">
                          <div className="w-full pb-[100%] relative mb-2">
                            <img
                              src={option.imageUrl}
                              alt={option.alt}
                              className="absolute inset-0 size-full rounded-md border-2 border-transparent transition-all hover:border-primary object-cover"
                            />
                          </div>
                          <div className="flex items-center">
                            <Checkbox
                              checked={checkboxField.value?.includes(
                                option.value,
                              )}
                              onCheckedChange={(checked) => {
                                const updatedValue = checked
                                  ? [
                                      ...(checkboxField.value || []),
                                      option.value,
                                    ]
                                  : checkboxField.value?.filter(
                                      (value) => value !== option.value,
                                    );
                                checkboxField.onChange(updatedValue);
                              }}
                              id={`checkbox-${option.value}`}
                            />
                            <FormLabel
                              htmlFor={`checkbox-${option.value}`}
                              className="cursor-pointer"
                              tooltip={option.tooltip}
                            >
                              {option.label && (
                                <span className="text-sm">{option.label}</span>
                              )}
                            </FormLabel>
                          </div>
                        </div>
                      </FormControl>
                    </FormItem>
                  )}
                />
              ))}
            </div>
          </FormControl>
        </FormItem>
      )}
    />
  );
};
