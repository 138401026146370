import {
  FileUploadFieldProps,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  withConditional,
} from "@bleu/ui";
import Dialog from "@components/ui/Dialog";
import { Cross1Icon } from "@radix-ui/react-icons";
import { cn } from "@utils/mergeClassNames";
import { cva } from "class-variance-authority";
import React, { useEffect, useReducer } from "react";
import { Trans } from "react-i18next";

import { AttachmentsTable } from "./AttachmentsTable";

const imageUploadVariants = cva("w-full", {
  variants: {
    size: {
      small: "size-24 p-0 max-w-24",
      medium: "size-48 p-0 max-w-48",
      large: "size-80 p-0 max-w-80",
    },
  },
  defaultVariants: {
    size: "medium",
  },
});

const initialState = {
  imagePreview: "",
  contentUploaded: false,
  downloadUrl: "",
  filename: "",
};

function fileReducer(state, action) {
  switch (action.type) {
    case "SET_FILE":
      return {
        ...state,
        contentUploaded: true,
        downloadUrl: action.downloadUrl,
        filename: action.filename,
        imagePreview: action.mode === "image" ? action.downloadUrl : "",
      };
    case "REMOVE_FILE":
      return { ...initialState };
    default:
      throw new Error();
  }
}

export const ProgramAttachmentUploadField =
  withConditional<FileUploadFieldProps>(({ form, field }) => {
    const [state, dispatch] = useReducer(fileReducer, initialState);
    const hiddenFileInput = React.useRef<HTMLInputElement>(null);
    const [defaultValue, setDefaultValue] = React.useState("");

    useEffect(() => {
      const value = form.getValues(field.name);
      setDefaultValue(value);
      if (!value) return;

      dispatch({
        type: "SET_FILE",
        downloadUrl: value,
        filename: value,
        mode: field.mode || "image",
      });
    }, []);

    const handleUpload = (attachment) => {
      form.setValue(field.name, attachment.id);

      dispatch({
        type: "SET_FILE",
        downloadUrl: attachment.url,
        filename: attachment.filename,
        mode: field.mode || "image",
      });

      form.setValue(`${field.name}_url`, attachment.url);
    };

    const handleClick = () => {
      if (hiddenFileInput.current) hiddenFileInput.current.click();
    };

    const handleRemoveContent = (event) => {
      event.stopPropagation();
      form.setValue(field.name, null);
      dispatch({ type: "REMOVE_FILE" });
    };

    return (
      <FormField
        control={form.control}
        name={field.name}
        rules={field.required ? { required: true } : undefined}
        render={() => (
          <FormItem>
            <FormLabel tooltip={field.tooltip}>{field.label}</FormLabel>
            <FormDescription>{field.description}</FormDescription>
            <FormControl>
              {/* @ts-ignore */}
              <Dialog.Root>
                {defaultValue !== form.getValues(field.name) && (
                  <input
                    type="hidden"
                    name={field.name}
                    value={form.getValues(field.name)}
                    defaultValue={form.getValues(field.name)}
                  />
                )}
                <div className="flex flex-col gap-0 items-start">
                  <div
                    className={cn(
                      "relative border-2 rounded-lg overflow-hidden border-dashed",
                      state.imagePreview
                        ? "inline-block cursor-pointer bg-cover"
                        : "w-full flex-col items-center",
                      imageUploadVariants(field.style),
                    )}
                  >
                    {state.contentUploaded && (
                      <button
                        type="button"
                        className="absolute top-0 right-0 m-1 z-50"
                        onClick={handleRemoveContent}
                        aria-label="Remove File"
                      >
                        <Cross1Icon className="text-2xl font-semibold" />
                      </button>
                    )}
                    <button
                      type="button"
                      className={cn(
                        "w-full h-full flex items-center justify-center relative",
                        imageUploadVariants(field.style),
                        state.contentUploaded ? "rounded-sm p-0" : "",
                      )}
                      onClick={handleClick}
                    >
                      {state.contentUploaded ? (
                        <>
                          {state.imagePreview ? (
                            <img
                              src={state.imagePreview}
                              alt="preview"
                              className="object-contain size-full rounded-sm"
                            />
                          ) : (
                            <span className="absolute inset-0 flex items-center justify-center truncate">
                              {state.filename}
                            </span>
                          )}
                          <div className="hover:ring-primary absolute inset-0 flex items-center justify-center rounded-sm bg-black/50 opacity-0 transition-opacity hover:opacity-100 hover:ring-2 hover:ring-offset-2">
                            <Dialog.Trigger asChild>
                              <button
                                type="button"
                                className={cn(
                                  "w-full h-full flex items-center justify-center relative",
                                )}
                              >
                                <span className="text-lg font-semibold text-white">
                                  <Trans>Click to choose file</Trans>
                                </span>
                              </button>
                            </Dialog.Trigger>
                          </div>
                        </>
                      ) : (
                        <Dialog.Trigger asChild>
                          <button
                            type="button"
                            className={cn(
                              "w-full h-full flex items-center justify-center relative",
                            )}
                          >
                            <span className="text-lg font-semibold">
                              <Trans>Choose file</Trans>
                            </span>
                          </button>
                        </Dialog.Trigger>
                      )}
                    </button>
                  </div>
                </div>
                <Dialog.Content className="w-5/6 p-4">
                  <AttachmentsTable handleUpload={handleUpload} field={field} />
                </Dialog.Content>
              </Dialog.Root>
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
    );
  });
