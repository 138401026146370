// JsFromRoutes CacheKey d8006e7316e247e9d75e44d023eb01fc
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from "@js-from-routes/client";

export default {
  index: /* #__PURE__ */ definePathHelper(
    "get",
    "/admin/v2/challenges/:challenge_id/participants",
  ),
  importCsvTemplate: /* #__PURE__ */ definePathHelper(
    "get",
    "/admin/v2/programs/:program_id/participants/import_csv_template",
  ),
  importCsv: /* #__PURE__ */ definePathHelper(
    "post",
    "/admin/v2/programs/:program_id/participants/import_csv",
  ),
  magicLink: /* #__PURE__ */ definePathHelper(
    "get",
    "/admin/v2/participants/:id/magic_link",
  ),
  toggleTag: /* #__PURE__ */ definePathHelper(
    "post",
    "/admin/v2/participants/:id/toggle_tag",
  ),
  resetActivities: /* #__PURE__ */ definePathHelper(
    "post",
    "/admin/v2/participants/:id/reset_activities",
  ),
  toggleParticipantStatus: /* #__PURE__ */ definePathHelper(
    "post",
    "/admin/v2/participants/:id/toggle_participant_status",
  ),
  create: /* #__PURE__ */ definePathHelper(
    "post",
    "/admin/v2/programs/:program_id/participants",
  ),
  new: /* #__PURE__ */ definePathHelper(
    "get",
    "/admin/v2/programs/:program_id/participants/new",
  ),
  edit: /* #__PURE__ */ definePathHelper(
    "get",
    "/admin/v2/participants/:id/edit",
  ),
  show: /* #__PURE__ */ definePathHelper("get", "/admin/v2/participants/:id"),
  update: /* #__PURE__ */ definePathHelper(
    "patch",
    "/admin/v2/participants/:id",
  ),
  destroy: /* #__PURE__ */ definePathHelper(
    "delete",
    "/admin/v2/participants/:id",
  ),
};
