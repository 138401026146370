import { Separator } from "@bleu/ui";
import React from "react";

import { DashboardTable } from "./DashboardTable";

export function DashboardTopUsers({ data, title }) {
  return (
    <div className="flex flex-col gap-y-2">
      <div className="flex items-center gap-x-2">
        <h2 className="text-2xl font-bold whitespace-nowrap">{title}</h2>
        <div className="grow">
          <Separator />
        </div>
      </div>
      <DashboardTable data={data} />
    </div>
  );
}
