import { Button, SectionTitle, SWRDataTable } from "@bleu/ui";
import Link from "@components/Link";
import { PlusIcon } from "@radix-ui/react-icons";
import React from "react";
import { useParams } from "react-router-dom";

function AddProgramProfileAttributesLink({
  resourceName,
  to,
}: {
  resourceName: string;
  to: string;
}) {
  return (
    <Link to={to}>
      <Button size="sm" className="ml-auto hidden h-8 lg:flex text-sm">
        <PlusIcon className="mr-2 size-4" />
        Add {resourceName}
      </Button>
    </Link>
  );
}

export default function ProgramProfileAttributesSetting() {
  const { program_id } = useParams();

  return (
    <>
      <div className="my-4 space-y-4">
        <div className="flex items-center justify-between space-y-2">
          <div>
            <SectionTitle>Profile Attributes</SectionTitle>
            <p className="text-muted-foreground">
              Here&apos;s a list of the profile attribues. They will be required
              to the participant.
            </p>
          </div>
        </div>
        <SWRDataTable
          fetchPath={`/admin/v2/programs/${program_id}/profile_attributes`}
          searchKey="display_name"
          action={
            <AddProgramProfileAttributesLink
              resourceName="Attribute"
              to={`/admin/v2/programs/${program_id}/settings/program/profile-attributes/new`}
            />
          }
        />
      </div>
    </>
  );
}
