import * as DineIcon from "@assets/lottie/dine-wired-outline-520-plate-fork-knife.json";
import * as MapIcon from "@assets/lottie/wired-outline-106-map.json";
import * as BasketIcon from "@assets/lottie/wired-outline-139-basket.json";
import * as InternetIcon from "@assets/lottie/wired-outline-959-internet.json";
import { Pagination, Select } from "@bleu/ui";
import Button from "@components/ui/Button";
import Card from "@components/ui/Card";
import LottieIcon from "@components/ui/LottieIcon";
import { useLocalStorage } from "@hooks/useLocalStorage";
import {
  DrawingPinIcon,
  ExclamationTriangleIcon,
  GridIcon,
  ListBulletIcon,
  MagnifyingGlassIcon,
  Pencil1Icon,
} from "@radix-ui/react-icons";
import { client } from "@utils/api-client";
import { usdFormatter } from "@utils/formatMoney";
import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  Await,
  defer,
  Link,
  useLoaderData,
  useNavigate,
  useNavigation,
  useSearchParams,
} from "react-router-dom";

const categories = [
  { id: 25, value: "Dining", icon: DineIcon },
  { id: 27, value: "Shopping", icon: BasketIcon },
  { id: 26, value: "Events & Attractions", icon: InternetIcon },
  { id: 91, value: "Travel", icon: MapIcon },
];

const getCategory = (id) => {
  const category = categories.find((category) => category.id === Number(id));
  return category ? category.value : "";
};

async function loader({ request }) {
  const url = new URL(request.url);
  if (
    !url.searchParams.get("zip_code") &&
    (!url.searchParams.get("latitude") || !url.searchParams.get("longitude"))
  ) {
    const zip_code = window.localStorage.getItem("perk-zipcode");
    if (zip_code) {
      url.searchParams.set("zip_code", zip_code);
    }
    const geolocationStr = window.localStorage.getItem("perk-geolocation");
    const geolocation = geolocationStr ? JSON.parse(geolocationStr) : null;

    if (geolocation?.latitude && geolocation?.longitude) {
      url.searchParams.set("latitude", geolocation.latitude);
      url.searchParams.set("longitude", geolocation.longitude);
    }

    if (!zip_code && !geolocation) {
      return defer({
        data: null,
      });
    }
  }

  const data = client("/offers/merchants?" + new URLSearchParams(url.search));
  return defer({
    data,
  });
}

function OffersListSkeleton() {
  return (
    <section className="mx-auto mb-10 mt-4">
      <div className="justify-left mb-2 flex w-full items-center justify-between space-x-1 py-1 sm:mb-0 sm:flex-row sm:items-center">
        <p className="animate-pulse font-bold"> Loading...</p>
        <p className="animate-pulse text-sm font-medium text-gray-700">
          Loading...
        </p>
      </div>
      <div className="mt-2 flex flex-col items-center justify-center bg-gray-100 pt-2">
        <div className="grid grid-cols-1 items-start gap-x-3 gap-y-10 sm:grid-cols-2 lg:grid-cols-3 xl:gap-x-4">
          {Array.from({ length: 6 }).map((_, i) => (
            <div
              key={i}
              className="mx-auto w-80 rounded-xl bg-gray-100 shadow-xl"
            >
              <div className="h-48 animate-pulse overflow-hidden bg-gray-200 p-3"></div>
              <div className="h- p-3">
                <div className="mt-2 grid grid-cols-3 gap-4">
                  <div className="h-8 animate-pulse rounded bg-gray-200"></div>
                  <div className="h-8 animate-pulse rounded bg-gray-200"></div>
                  <div className="h-8 animate-pulse rounded bg-gray-200"></div>
                  <div className="col-span-2 h-8 animate-pulse rounded bg-gray-200"></div>
                  <div className="h-8 animate-pulse rounded  bg-gray-200"></div>
                  <div></div>
                  <div className="col-span-2"></div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

const OfferLink = ({ merchant }) => {
  const [searchParams] = useSearchParams();
  searchParams.set("keyword", merchant.merchantName);

  const amountInDollars = usdFormatter.format(merchant.offers[0].dollarValue);

  return (
    <Link
      to={merchant.merchantId + "?" + searchParams.toString()}
      className="text-black no-underline"
    >
      <Card.Root>
        {/* @ts-expect-error TS(2322) FIXME: Type '{ src: any; }' is not assignable to type 'In... Remove this comment to see the full error message */}
        <Card.Logo src={merchant.logoPath} />
        <Card.Content>
          <Card.Title className="box-border text-center text-[1.2em] font-semibold leading-none text-black">
            {merchant.merchantName}
          </Card.Title>
          <Card.Description>
            <ul className="mb-0 mt-2 list-disc pl-4 text-left text-xs font-light text-black">
              <li>{merchant.offers[0].offerTextShort}</li>
              {merchant.offers.length > 1 && (
                <li>{merchant.offers[1].offerTextShort}</li>
              )}
            </ul>
            {merchant.offers.length > 2 && (
              <div className="text-perk-primary list-disc pl-4 text-left text-sm font-bold">
                + {merchant.offers.length - 2} more offers
              </div>
            )}
          </Card.Description>
          <Card.Footer className="flex items-center justify-center gap-1 px-6 py-1">
            <span className="text-md inline-block rounded-full font-bold italic text-gray-500">
              {amountInDollars} Value
            </span>
          </Card.Footer>
        </Card.Content>
      </Card.Root>
    </Link>
  );
};

const OffersList = ({
  data,
  category,
  location = "home",
  handleClearLocation,
}) => {
  const firstItem = data.pageSize * (data.page - 1) + 1;
  const lastItem = Math.min(data.pageSize * data.page, data.totalItems);
  const categoryLabel = category ? getCategory(category) : null;

  return (
    <section className="mx-auto mb-10 mt-4">
      <div className="justify-left mb-2 flex w-full flex-col items-center space-x-1 py-1 sm:mb-0 sm:flex-row sm:items-center sm:justify-between">
        <div className="flex w-full flex-row items-start justify-around sm:w-1/2 sm:flex-row sm:items-center sm:justify-normal sm:gap-1">
          <div>
            <span>You have </span>
            <span className="font-bold">{data.totalOffers} </span>
            <span className="font-bold">
              {categoryLabel ? `${categoryLabel} Offers` : "Offers"}
            </span>
            <span> from {data.totalItems} Merchants</span>
            {location && <span className="font-normal"> near {location}</span>}
          </div>
          <button
            className="text-sm font-medium underline"
            onClick={() => handleClearLocation()}
          >
            change location
          </button>
        </div>
        <p className="mt-2 text-sm text-gray-700 sm:mt-0">
          Showing <span className="font-medium">{firstItem}</span> to{" "}
          <span className="font-medium">{lastItem}</span> of{" "}
          <span className="font-medium">{data.totalItems}</span> Merchants
        </p>
      </div>
      <div className="grid grid-cols-1 items-start gap-x-3 gap-y-10 sm:grid-cols-2 lg:grid-cols-3 xl:gap-x-4">
        {data.result &&
          data.result.map((merchant) => <OfferLink merchant={merchant} />)}
      </div>
    </section>
  );
};

function LocationPermission({ setGeolocation, setZipcode }) {
  const navigate = useNavigate();
  const zipcodeRef = React.useRef(null);
  const handleGeoLocation = async () => {
    await navigator.geolocation.getCurrentPosition(
      (position) => {
        setGeolocation({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        });
        navigate(
          `/my-offers?${new URLSearchParams(
            // @ts-expect-error TS(2345) FIXME: Argument of type '{ latitude: number; longitude: n... Remove this comment to see the full error message
            {
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
            },
          ).toString()}`,
          {
            replace: true,
          },
        );
      },
      (err) => setGeolocation(null),
    );
  };

  const handleZipcode = () => {
    setZipcode(zipcodeRef.current.value);
    navigate(
      `/my-offers?${new URLSearchParams({
        zip_code: zipcodeRef.current.value,
      }).toString()}`,
      {
        replace: true,
      },
    );
  };

  return (
    <div className="size-full bg-black/80">
      <div className="flex h-[50rem] items-center justify-center">
        <Card.Root className="max-w-sm rounded-2xl pb-3">
          <Card.Title className="mt-4 px-20 text-center text-xl font-bold">
            Let's find the deals, offers & savings near you
          </Card.Title>
          <LottieIcon iconData={InternetIcon} width={100} height={100} />
          <Card.Description>
            <p className="px-16 text-center text-sm font-semibold">
              To get started, please enter your zip / postal code below:
            </p>
          </Card.Description>
          <Card.Content className="flex flex-col gap-6">
            <div className="flex items-center justify-center gap-3">
              <input
                ref={zipcodeRef}
                type="text"
                className=" rounded-full px-3 py-2 text-gray-500"
                placeholder="your zip code"
              />
              {/* @ts-expect-error TS(2322) FIXME: Type '{ children: string; className: string; onCli... Remove this comment to see the full error message */}
              <Button className="rounded-full" onClick={handleZipcode}>
                View offers
              </Button>
            </div>
            <div className="px-10 text-center text-sm font-semibold">
              or{" "}
              <button
                onClick={handleGeoLocation}
                className="border-0 underline "
              >
                {" "}
                click here
              </button>{" "}
              to find your location automatically
            </div>
          </Card.Content>
        </Card.Root>
      </div>
    </div>
  );
}

interface FormValues {
  keyword: string;
  category: string;
  zip_code: string;
  sort: string;
}

export default function Offers() {
  // @ts-expect-error TS(2339) FIXME: Property 'data' does not exist on type '{}'.
  const { data } = useLoaderData();
  const navigate = useNavigate();
  const { state } = useNavigation();
  const [searchParams] = useSearchParams();
  const [zip_code, setZipcode] = useLocalStorage("perk-zipcode");
  const [geolocation, setGeolocation] = useLocalStorage("perk-geolocation");

  const { register, control, getValues, setValue, watch } = useForm<FormValues>(
    {
      defaultValues: {
        keyword: searchParams.get("keyword") || "",
        category: searchParams.get("category") || "",
        zip_code: searchParams.get("zip_code") || zip_code || "",
        sort: searchParams.get("sort") || "",
      },
    },
  );
  useEffect(() => {
    if (zip_code) {
      setValue("zip_code", zip_code);
    }
    setValue("category", "");
    setValue("keyword", "");
  }, [zip_code]);

  const onSubmit = async (e) => {
    e.preventDefault();
    const values = getValues();

    const body = {
      ...values,
      zip_code: values.zip_code || zip_code || "",
      latitude: geolocation?.latitude || "",
      longitude: geolocation?.longitude || "",
      category: values.category !== "allCategories" ? values.category : "",
      sort: values.sort || "",
    };

    if (values.zip_code !== zip_code) {
      setZipcode(values.zip_code);
    }

    navigate(`/my-offers?${new URLSearchParams(body).toString()}`, {
      replace: true,
    });
  };

  const handleNextPage = (currentPage) => {
    const values = getValues();

    const body = {
      ...values,
      zip_code: values.zip_code || zip_code || "",
      latitude: geolocation?.latitude || "",
      longitude: geolocation?.longitude || "",
      category: values.category !== "allCategories" ? values.category : "",
      sort: values.sort || "",
      page: Number(currentPage) + 1,
    };

    if (values.zip_code !== zip_code) {
      setZipcode(values.zip_code);
    }

    // @ts-expect-error TS(2345) FIXME: Argument of type '{ zip_code: any; latitude: any; ... Remove this comment to see the full error message
    navigate(`/my-offers?${new URLSearchParams(body).toString()}`, {
      replace: true,
    });
  };

  const handlePrevPage = (currentPage) => {
    const values = getValues();

    const body = {
      ...values,
      zip_code: values.zip_code || zip_code || "",
      latitude: geolocation?.latitude || "",
      longitude: geolocation?.longitude || "",
      category: values.category !== "allCategories" ? values.category : "",
      sort: values.sort || "",
      page: Number(currentPage) - 1,
    };

    if (values.zip_code !== zip_code && values.zip_code !== "") {
      setZipcode(values.zip_code);
    }

    // @ts-expect-error TS(2345) FIXME: Argument of type '{ zip_code: any; latitude: any; ... Remove this comment to see the full error message
    navigate(`/my-offers?${new URLSearchParams(body).toString()}`, {
      replace: true,
    });
  };

  const handleCategorySubmit = (e) => {
    e.preventDefault();
    setValue("category", e.target.id);
    onSubmit(e);
  };

  const handleClearLocation = () => {
    setZipcode("");
    setGeolocation("");
  };

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }, 100);
  }, [data, state]);

  if (!zip_code && !geolocation) {
    return (
      <LocationPermission
        setGeolocation={setGeolocation}
        setZipcode={setZipcode}
      />
    );
  }

  return (
    <div className="mt-28 flex flex-col items-center justify-center bg-gray-100 pt-8 lg:mt-20">
      <div className="flex flex-col items-center justify-center gap-2 px-10">
        <h1 className="mb-2 text-6xl font-bold text-[#45485F] lg:text-9xl">
          Explore
        </h1>
        <h2 className="mb-4 text-2xl font-bold text-[#45485F] lg:text-6xl">
          Perks and Benefits
        </h2>
      </div>
      <form
        onSubmit={(e) => onSubmit(e)}
        className="mb-3 mt-2 flex flex-col -space-y-px rounded-md md:flex-row"
      >
        <div className="flex flex-col -space-x-px md:flex-row">
          <div className="relative mt-2 w-52 rounded-md shadow-sm md:mt-0">
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
              <Pencil1Icon
                className="size-5 text-gray-400"
                aria-hidden="true"
              />
            </div>
            <input
              type="text"
              name="keyword"
              id="keyword"
              className="block w-full rounded-lg border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-600 sm:rounded-l-lg sm:rounded-r-none sm:text-sm sm:leading-6"
              placeholder="search name"
              {...register("keyword")}
            />
          </div>
          <div className="relative mt-2 w-52 rounded-md shadow-sm md:mt-0">
            <Controller
              control={control}
              name="category"
              render={({ field: { onChange, value } }) => (
                <Select.SelectRoot
                  onValueChange={onChange}
                  defaultValue={value}
                  name="category"
                >
                  <Select.SelectTrigger className="data-[placeholder]:text-gray-400 rounded-lg border-0 py-1.5 h-full text-sm leading-6 text-gray-900 ring-1 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-600 md:rounded-none bg-white ring-offset-gray-300">
                    <Select.SelectValue placeholder="category" />
                  </Select.SelectTrigger>
                  <Select.SelectContent className="z-20 bg-white text-gray-900">
                    <Select.SelectGroup>
                      <Select.SelectLabel className="pl-4">
                        Categories
                      </Select.SelectLabel>
                      {categories.map((option) => (
                        <Select.SelectItem
                          key={option.id}
                          value={option.id.toString()}
                          className="bg-white leading-none text-gray-900 data-[highlighted]:bg-gray-300 data-[highlighted]:font-semibold data-[disabled]:text-gray-400 data-[highlighted]:outline-none"
                        >
                          {option.value}
                        </Select.SelectItem>
                      ))}
                      <Select.SelectItem
                        key={-1}
                        value="allCategories"
                        className="bg-white leading-none text-gray-900 data-[highlighted]:bg-gray-300 data-[highlighted]:font-semibold data-[disabled]:text-gray-400 data-[highlighted]:outline-none"
                      >
                        All categories
                      </Select.SelectItem>
                    </Select.SelectGroup>
                  </Select.SelectContent>
                </Select.SelectRoot>
              )}
            />
          </div>
          <div className="relative mt-2 w-52 rounded-md shadow-sm md:mt-0">
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
              <DrawingPinIcon
                className="size-5 text-gray-400"
                aria-hidden="true"
              />
            </div>
            <input
              type="text"
              name="zip_code"
              id="zip_code"
              className="block w-full rounded-lg border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-600 sm:text-sm sm:leading-6 md:rounded-none"
              placeholder="ZIP code"
              {...register("zip_code")}
            />
          </div>
          <div className="relative mt-2 shadow-sm md:mt-0">
            <button
              type="submit"
              className="mb-0 flex size-full items-center justify-center rounded-lg border-0 px-3 py-1.5 text-gray-900 outline-none ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-0 focus:ring-inset focus:ring-gray-600 sm:rounded-l-none sm:rounded-r-lg sm:text-sm sm:leading-6"
            >
              <MagnifyingGlassIcon
                className="size-5 text-gray-400"
                aria-hidden="true"
              />
            </button>
          </div>
        </div>
      </form>

      <div className="mb-5 flex h-10 w-full justify-center gap-4 lg:gap-12">
        {categories.map((option) => (
          <div
            key={option.id}
            className="flex items-center justify-center text-xs md:gap-2 md:text-base"
          >
            <LottieIcon iconData={option.icon} width={25} height={25} />
            <button
              className="font-bold outline-none focus:ring-0"
              // @ts-expect-error TS(2322) FIXME: Type 'number' is not assignable to type 'string'.
              id={option.id}
              onClick={(e) => handleCategorySubmit(e)}
            >
              {option.value}
            </button>
          </div>
        ))}
      </div>

      <section className="h-10 w-full bg-gray-900 px-28 py-2">
        <div className="flex size-full items-center justify-between">
          <div className="flex h-10 items-center justify-between gap-4"></div>
          <div className="flex gap-3">
            <button>
              <GridIcon className="size-4 text-gray-400" aria-hidden="true" />
            </button>
            <button>
              <ListBulletIcon
                className="size-5 text-gray-400"
                aria-hidden="true"
              />
            </button>
          </div>
        </div>
      </section>

      {state === "idle" && (
        <React.Suspense fallback={<OffersListSkeleton />}>
          <Await resolve={data} errorElement={<p>Error offers!</p>}>
            {(data) => (
              <>
                {data ? (
                  <>
                    <section className="mx-auto mb-3 mt-4">
                      <OffersList
                        data={data}
                        category={watch("category")}
                        location={zip_code}
                        handleClearLocation={handleClearLocation}
                      />
                    </section>
                    <footer className="mx-5 mb-5 flex items-center gap-2">
                      <ExclamationTriangleIcon />
                      <span className="text-sm text-gray-600">
                        THIS PAGE CANNOT BE PRINTED AND USED AS A VALID COUPON
                      </span>
                    </footer>
                    <Pagination
                      page={data.page}
                      pageSize={data.pageSize}
                      totalItems={data.totalItems}
                      handleNext={handleNextPage}
                      handlePrev={handlePrevPage}
                    />
                  </>
                ) : (
                  <div className="my-10 flex h-80 w-full justify-center">
                    <p className="text-lg font-medium">
                      No merchants match your current parameters. Please adjust
                      your parameters and try submitting again.
                    </p>
                  </div>
                )}
              </>
            )}
          </Await>
        </React.Suspense>
      )}

      {["loading", "submitting"].includes(state) && <OffersListSkeleton />}
    </div>
  );
}

Offers.loader = loader;
