import { buildForm } from "@bleu/ui";
import React from "react";
import { useOutletContext } from "react-router-dom";

import { BrandingOutletContextType } from "./(components)/Layout";

const FIELDS = [
  {
    name: "element_css_style[perk-primary]",
    label: "Brand Color",
    description: "Choose your primary brand color",
    type: "color_picker",
    required: true,
  },
  {
    name: "element_css_style[perk-primary-content]",
    label: "Brand Text Color",
    description:
      "This will be used as the primary color for any content (text, links, etc)",
    type: "color_picker",
    required: true,
  },
  {
    name: "element_css_style[perk-header-color]",
    label: "Header Background Color",
    description: "Choose your header background color",
    type: "color_picker",
    required: true,
  },
  {
    name: "element_css_style[perk-header-content-color]",
    label: "Header Font Color",
    description: "Choose your header font color",
    type: "color_picker",
    required: true,
  },
  {
    name: "element_css_style[perk-header-title-color]",
    label: "Hero Font Color",
    description: "Choose your hero font color",
    type: "color_picker",
    required: true,
  },
  {
    name: "element_css_style[perk-challenge-tile-background]",
    label: "Challenge Tile Background Color",
    description: "Choose your challenge card background color",
    type: "color_picker",
    required: true,
  },
  {
    name: "element_css_style[perk-reward-tile-background-color]",
    label: "Reward Tile Background Color",
    description: "Choose your reward card background color",
    type: "color_picker",
    required: true,
  },
  {
    name: "element_css_style[perk-footer-color]",
    label: "Footer Background Color",
    description: "Choose your footer background color",
    type: "color_picker",
    required: true,
  },
  {
    name: "element_css_style[perk-footer-content-color]",
    label: "Footer Font Color",
    description: "Choose your footer font color",
    type: "color_picker",
    required: true,
  },
];

export default function ProgramColorsPage() {
  const { form } = useOutletContext<BrandingOutletContextType>();

  return (
    <div>
      <h2 className="pt-8 text-2xl font-bold tracking-tight mb-5">Colors</h2>
      <div className="grid lg:grid-cols-1 pr-20 gap-4">
        {/** @ts-ignore */}
        {buildForm(FIELDS, form)}
      </div>
    </div>
  );
}
