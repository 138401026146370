import { cn, toast, Toaster } from "@bleu/ui";
import Card from "@components/ui/Card";
import React, { useEffect, useRef, useState } from "react";
import ReactPlayer from "react-player";

import { CardContent } from "./components/CardContent";
import { CardHeaderContent } from "./components/CardHeader";
import { ChallengeData } from "./components/types";

export function Video({
  data,
  handleSubmit,
}: {
  data: ChallengeData;
  handleSubmit: () => void;
}) {
  const [videoProgress, setVideoProgress] = useState(0);
  const hasSubmitted = useRef(false);
  const completedCondition = data?.completed_by_current_participant_on !== null;

  const handleVideoProgress = (state) => {
    const progress = (state.played * 100).toFixed(2);
    setVideoProgress(Number(progress));
  };

  useEffect(() => {
    const durationToSubmit = data.duration;
    if (
      !completedCondition &&
      videoProgress >= durationToSubmit &&
      !hasSubmitted.current
    ) {
      toast({
        title: "Challenge Submitted!",
        description: " Earn more points by completing more challenges.",
        variant: "default",
      });
      handleSubmit();
      hasSubmitted.current = true;
    }
  }, [videoProgress, data.duration, handleSubmit, completedCondition]);

  return (
    <Card.Root className="w-full max-w-none bg-white border z-[1500] !rounded-perk-cards">
      <Toaster position="top-right" />
      <Card.Header className={cn(completedCondition && "pb-0")}>
        <CardHeaderContent
          data={data}
          completedCondition={completedCondition}
        />
        <div className="mb-2">
          <CardContent data={data} completedCondition={completedCondition} />
          {data.challenge_type === "video" && data.link_url && (
            <div className="relative mb-3 mx-3">
              <ReactPlayer
                url={data.link_url}
                width="100%"
                onProgress={handleVideoProgress}
              />
              <div className="absolute bottom-0 left-0 w-full h-1 bg-gray-200">
                <div
                  className="h-full bg-perk-primary transition-all duration-100 ease-out"
                  style={{ width: `${videoProgress}%` }}
                />
                <div className="flex justify-end">
                  <span className="text-sm">{videoProgress.toFixed(0)}%</span>
                </div>
              </div>
            </div>
          )}
        </div>
      </Card.Header>
    </Card.Root>
  );
}
