import { adminV2ProgramAttachments } from "@api/index";
import {
  BaseField,
  buildForm,
  Form,
  SubmitButton,
  TabsContent,
  TabsList,
  TabsRoot,
  TabsTrigger,
  toast,
} from "@bleu/ui";
import {
  DateElement,
  InfoList,
} from "@pages/admin/(components)/DetailsCardElements";
import { formatBytes } from "@utils/formatBytes";
import { serialize } from "object-to-formdata";
import React, { useEffect } from "react";
import { useForm, UseFormReturn } from "react-hook-form";
import { useSWRConfig } from "swr";
import useSWRMutation from "swr/mutation";

import { attachmentFetcher } from "./attachmentFetcher";

const FIELDS = [
  {
    name: "title",
    label: "Title",
    description: "This is your file name",
    type: "input",
  },
  {
    name: "alt_text",
    label: "Alt text",
    description: "This is your file alternative text",
    type: "input",
  },
] as BaseField[];

const info = (media) => {
  return [
    {
      value: media.title,
      label: "Title",
    },
    {
      label: "Alt text",
      value: media.alt_text,
    },
    {
      label: "Content Type",
      value: media.content_type,
    },
    {
      label: "Dimensions",
      value: media.dimension,
    },
    {
      label: "File Size",
      value: formatBytes(media.byte_size),
    },
    {
      label: "Created At",
      element: <DateElement date={media.created_at} />,
      value: media.created_at,
    },
  ];
};

export function AttachmentDetails({ media, handleUseFile }) {
  const { title, alt_text, file, id } = media;
  const { trigger, isMutating } = useSWRMutation(
    adminV2ProgramAttachments.update.path({ id }),
    attachmentFetcher,
  );
  const { mutate } = useSWRConfig();

  const form = useForm({
    defaultValues: {
      title: title,
      alt_text: alt_text,
    },
  }) as UseFormReturn<typeof form.defaultValues>;

  useEffect(() => {
    form.reset({
      title: title || "",
      alt_text: alt_text || "",
    });
  }, [media]);

  useEffect(() => {
    mutate(() => true, undefined, {
      revalidate: true,
    });
  }, [isMutating]);

  const handleSubmit = async () => {
    const isValid = await form.trigger();
    if (!isValid) return;

    const values = form.getValues();
    const formData = serialize(values);

    const result = await trigger({ formData, method: "PUT" });

    toast({
      title: result?.success ? "Success!" : "Error!!!",
      description: result?.success
        ? "Attachment updated successfully."
        : result?.errors,
      variant: result?.success ? "default" : "destructive",
    });
  };

  return (
    <TabsRoot
      defaultValue="preview"
      className="border-2 border-dashed rounded-md p-2"
    >
      <TabsList className="w-full">
        <TabsTrigger value="preview">Preview</TabsTrigger>
        <TabsTrigger value="edit">Edit</TabsTrigger>
      </TabsList>
      <TabsContent className="focus-visible:ring-0" value="preview">
        <div className="flex flex-col gap-y-4">
          <img src={file.url} className="object-contain size-full rounded-sm" />
          <div className="truncate">
            <InfoList data={info(media)} />
          </div>
          <SubmitButton
            type="button"
            onClick={() =>
              handleUseFile({
                url: file.url,
                filename: file.filename,
                id: id,
              })
            }
          >
            Use this file
          </SubmitButton>
        </div>
      </TabsContent>
      <TabsContent value="edit" className="focus-visible:ring-0 mt-4">
        <Form {...form} className="space-y-4 flex flex-col">
          <div className="flex flex-wrap gap-3 p-2">
            {buildForm(FIELDS, form)}
          </div>
          <SubmitButton
            type="button"
            onClick={handleSubmit}
            isSubmitting={isMutating}
          >
            Save
          </SubmitButton>
        </Form>
      </TabsContent>
    </TabsRoot>
  );
}
