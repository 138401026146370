import { formatDate, formatNumber, i18n, useTheme } from "@bleu/ui";
import { Plot } from "@components/Plot";
import { Data, PlotType } from "plotly.js";
import React from "react";

export function DashboardUsersGrowthChart({ usersGrowth }) {
  const formattedY = usersGrowth.y.map((y) =>
    formatNumber(y, 1, "decimal", "standard", 0.001, i18n.default.language),
  );

  const hoverTemplates = formattedY.map(
    (formattedValue, index) =>
      `${formattedValue} Active Users in ${formatDate(usersGrowth.x[index])}<extra></extra>`,
  );
  const chartData: Data = {
    orientation: "h" as const,
    //todo, get this color from theme
    line: { shape: "linear", color: "#bba16b" } as const,
    marker: {
      color: "#bba16b",
      opacity: 0.8,
    },
    type: "scatter" as PlotType,
    x: usersGrowth.x,
    y: usersGrowth.y,
    text: hoverTemplates,
    hovertemplate: "%{text}",
  };

  const { theme } = useTheme();

  //todo, get these color from theme
  const gridColors =
    theme === "dark" ? "hsl(215, 28%, 17%)" : "hsl(0, 0%, 90%)";
  const lineColors =
    theme === "dark" ? "hsl(0, 0%, 100%)" : "hsl(225, 67%, 2%)";

  return (
    <Plot
      config={{
        modeBarButtonsToRemove: [
          "pan2d",
          "lasso2d",
          "select2d",
          "zoom2d",
          "autoScale2d",
          "toImage",
        ],
      }}
      data={[chartData]}
      layout={{
        showlegend: false,
        barmode: "overlay",
        dragmode: "zoom",
        height: 300,
        margin: { t: 30, r: 20, l: 20, b: 30 },
        font: {
          family: "Inter var",
        },
        xaxis: {
          type: "date",
          gridcolor: gridColors,
          linecolor: lineColors,
          tickfont: {
            color: lineColors,
          },
        },
        yaxis: {
          dtick: 1,
          position: 0,
          side: "left",
          type: "log",
          //@ts-ignore
          tickson: "boundaries",
          gridcolor: gridColors,
          linecolor: lineColors,
          tickformat: ",d",
          tickfont: {
            color: lineColors,
          },
        },
        modebar: {
          orientation: "v",
        },
      }}
    />
  );
}
