import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  RadioGroup,
  RadioGroupItem,
} from "@bleu/ui";
import React from "react";

export const ImageRadioGroupField = ({ field, form }) => {
  return (
    <FormField
      control={form.control}
      name={field.name}
      rules={field.required ? { required: true } : undefined}
      render={({ field: formField }) => (
        <FormItem className="space-y-0 w-full">
          <FormLabel tooltip={field.tooltip}>{field.label}</FormLabel>
          {field.description && (
            <p className="text-sm text-gray-500">{field.description}</p>
          )}
          <FormControl className="w-full">
            <RadioGroup
              onValueChange={formField.onChange}
              defaultValue={formField.value}
              className="flex"
            >
              {field.options.map((option) => (
                <FormItem
                  key={option.value}
                  className="relative flex flex-col w-full"
                >
                  <FormControl>
                    <div className="flex flex-col items-center grow">
                      <div className="w-full pb-[100%] relative mb-2">
                        <img
                          src={option.imageUrl}
                          alt={option.alt}
                          className="absolute inset-0 size-full rounded-md border-2 border-transparent transition-all hover:border-primary object-cover"
                        />
                      </div>
                      <div className="flex items-center space-x-2">
                        <RadioGroupItem
                          value={option.value}
                          id={`radio-${option.value}`}
                        />
                        <FormLabel
                          htmlFor={`radio-${option.value}`}
                          className="cursor-pointer"
                          tooltip={option.tooltip}
                        >
                          {option.label && (
                            <span className="text-sm">{option.label}</span>
                          )}
                        </FormLabel>
                      </div>
                    </div>
                  </FormControl>
                </FormItem>
              ))}
            </RadioGroup>
          </FormControl>
        </FormItem>
      )}
    />
  );
};
