import { adminV2ProgramAttachments } from "@api/index";
import { useSWRDataTable } from "@bleu/ui";
import { DataTable } from "@components/table/DataTable";
import { useTableContext } from "@components/table/TableContext";
import { TablePagination } from "@components/table/TablePagination";
import { TableToolbar } from "@components/table/TableToolbar";
import { useDialog } from "@components/ui/Dialog";
import { useProgram } from "@contexts/ProgramContext";
import { DrawingPinFilledIcon } from "@radix-ui/react-icons";
import { formatBytes } from "@utils/formatBytes";
import React from "react";
import { useParams } from "react-router-dom";

import { AttachmentDetails } from "./AttachmentDetails";
import { AttachmentUpload } from "./AttachmentUpload";

function timeout(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

async function sleep(fn, delay, ...args) {
  await timeout(delay);
  return fn(...args);
}

const getRowItem = (row, name) => {
  return row
    .getVisibleCells()
    .find((cell) => cell.column.id === name)
    .getValue();
};

function ImageContainer({ row, uploadedFiles, setSelectedImage }) {
  const [renderAttempts, setRenderAttempts] = React.useState(0);

  const getFallbackImage = (row) => {
    return uploadedFiles.find(
      (file) => file.id.toString() === getRowItem(row, "id").toString(),
    )?.fallback;
  };

  const onRenderError = async ({ currentTarget }) => {
    if (renderAttempts >= 3) return;
    const retryDelay = Math.pow(2, renderAttempts) * 1000; // Exponential backoff

    await sleep(() => {
      currentTarget.onerror = () => onRenderError(currentTarget);
      currentTarget.src = getRowItem(row, "file")?.url || getFallbackImage(row);
      setRenderAttempts((attempts) => attempts + 1);
    }, retryDelay);
  };

  return (
    <div
      className="border-2 rounded-lg p-2 hover:scale-[101%] aspect-square"
      onClick={() => {
        setSelectedImage(row.original);
      }}
    >
      <img
        src={getRowItem(row, "file")?.url}
        className="size-40 object-contain transition-all"
        onError={onRenderError}
      />
      <div className="flex flex-col justify-left">
        <span className="text-xs text-muted-foreground">
          {getRowItem(row, "content_type")}
        </span>
        <span className="text-xs text-muted-foreground">
          {getRowItem(row, "dimension")}
        </span>
        <span className="text-xs text-muted-foreground truncate">
          {getRowItem(row, "filename")}
        </span>
        <span className="text-xs text-muted-foreground">
          {getRowItem(row, "byte_size") &&
            formatBytes(getRowItem(row, "byte_size"))}
        </span>
      </div>
    </div>
  );
}

function TableItems({ setSelectedImage, uploadedFiles }) {
  // @ts-expect-error TS(2339) FIXME: Property 'table' does not exist on type '{}'.
  const { table } = useTableContext();

  return (
    <>
      {table.getRowModel().rows?.length ? (
        table
          .getRowModel()
          .rows.map((row, index) => (
            <ImageContainer
              key={getRowItem(row, "id")}
              row={row}
              setSelectedImage={setSelectedImage}
              uploadedFiles={uploadedFiles}
            />
          ))
      ) : (
        <div className="mt-4 w-full mr-4 overflow-y-auto max-h-[80vh] min-h-[10vh]">
          No results, upload a file!
        </div>
      )}
    </>
  );
}

export function AttachmentsTable({ handleUpload, field }) {
  const program = useProgram();
  const params = useParams();
  const program_id = params.program_id || program?.id;
  const [uploadedFiles, setUploadedFiles] = React.useState([]);

  const initialTableState = {
    pagination: {
      pageIndex: 0,
      pageSize: 20,
    },
  };

  const { data, error, tableState, setTableState, isLoading } = useSWRDataTable(
    adminV2ProgramAttachments.index.path({ program_id }),
    initialTableState,
  );

  // @ts-expect-error TS(2339) FIXME: Property 'close' does not exist on type '{}'.
  const { close } = useDialog();

  const [selectedImage, setSelectedImage] = React.useState(null);

  const handleUseFile = (file) => {
    handleUpload(file);
    close();
  };

  if (isLoading && !data) {
    return (
      <div className="flex items-center justify-center max-h-[80vh] min-h-[80vh] animate-pulse text-3xl">
        Wait a second. We are loading the data for you...
      </div>
    );
  }

  return (
    <div>
      <DataTable
        data={data}
        error={error}
        tableState={tableState}
        setTableState={setTableState}
      >
        <div className="">
          <TableToolbar action={undefined} showViewOptions={false} />
          <div className="flex">
            <div
              className="mt-4 w-full mr-4 grid grid-flow-row-dense auto-rows-max gap-4 overflow-y-auto max-h-[80vh] min-h-[80vh] scrollbar-thumb-rounded-full scrollbar-track-rounded-full scrollbar scrollbar-thumb-white scrollbar-track-transparent"
              style={{
                gridTemplateColumns: "repeat(auto-fill, minmax(150px, 1fr))",
              }}
            >
              <AttachmentUpload
                programId={program_id}
                field={field}
                setUploadedFiles={setUploadedFiles}
              />
              <TableItems
                setSelectedImage={setSelectedImage}
                uploadedFiles={uploadedFiles}
              />
            </div>
            <div className="w-1/4 mx-4 mt-4">
              {selectedImage ? (
                <AttachmentDetails
                  media={selectedImage}
                  handleUseFile={handleUseFile}
                />
              ) : (
                <div className="flex flex-col items-center justify-center h-40 border-4 border-dashed rounded-md">
                  <DrawingPinFilledIcon />
                  <span className="text-center">
                    Select an item to preview it
                  </span>
                </div>
              )}
            </div>
          </div>
          <TablePagination />
        </div>
      </DataTable>
    </div>
  );
}
