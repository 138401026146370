import { adminV2ProfileAttributes } from "@api/index";
import { toast } from "@bleu/ui";
import { client } from "@utils/api-client";
import React from "react";
import { json, redirect, useLoaderData, useParams } from "react-router-dom";

import { SettingsCard } from "../../(components)/SettingsCard";

const loader = async ({ params }) => {
  const { program_id } = params;
  const { data } = await adminV2ProfileAttributes.new({ program_id });
  return json({ data });
};

const action = async ({ params, request }) => {
  const formData = await request.formData();
  const { program_id } = params;

  const path = adminV2ProfileAttributes.create.path({ program_id });
  const data = await client(path, { body: formData });

  toast({
    title: data?.success ? "Success!" : "Error!!!",
    description: data?.success
      ? "Profile Attribute created successfully."
      : data?.errors,
    variant: data?.success ? "default" : "destructive",
  });

  if (data?.success) {
    return redirect(
      `/admin/v2/programs/${program_id}/settings/program/profile-attributes`,
    );
  }

  return json({ ok: false });
};
function NewProfileAttributePage() {
  // @ts-expect-error TS(2339) FIXME: Property 'data' does not exist on type '{}'.
  const { data } = useLoaderData();
  const { program_id } = useParams();

  const defaultValues = data.defaultValues;

  return (
    <div className="my-8 space-y-4">
      <SettingsCard
        defaultValues={defaultValues}
        fields={data.fields}
        title={"Program Pages"}
        action={{ path: adminV2ProfileAttributes.create.path({ program_id }) }}
        _method="create"
      />
    </div>
  );
}

NewProfileAttributePage.loader = loader;
NewProfileAttributePage.action = action;
export default NewProfileAttributePage;
