import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
  Button,
  Checkbox,
  Skeleton,
  SubmitButton,
  toast,
} from "@bleu/ui";
import React, { useEffect, useState } from "react";
import { useNavigate, useNavigation, useParams } from "react-router-dom";

interface syncWinnerData {
  eligible_participants_count: number;
  limit_left: number;
}

const SkeletonLoader = () => (
  <>
    <AlertDialogTitle>
      <Skeleton className="h-7 w-3/4" />
    </AlertDialogTitle>
    <AlertDialogDescription>
      <Skeleton className="h-3.5 w-full" />
      <Skeleton className="h-3.5 w-full mt-1" />
    </AlertDialogDescription>
    <AlertDialogFooter>
      <div className="w-full flex justify-end gap-x-2">
        <Skeleton className="h-9 w-20" />
        <Skeleton className="h-9 w-20" />
      </div>
    </AlertDialogFooter>
  </>
);

export function SyncWinnersDialog({
  actionData,
  fetchPossibleWinnersToSync,
  syncWinnersSubmission,
}) {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [data, setData] = useState<syncWinnerData | undefined>(undefined);
  const [sendEmail, setSendEmail] = useState(false);
  const { state } = useNavigation();
  const { reward_id } = useParams();
  const navigate = useNavigate();

  const isLoading = !data || state === "submitting";

  useEffect(() => {
    if (
      actionData?.intent === "fetch_possible_winners_to_sync" &&
      actionData?.success
    ) {
      if (actionData.data.success === true) {
        setData(actionData.data.data);
      }
    }
    if (actionData?.intent === "sync_winners" && actionData?.success) {
      setIsDialogOpen(false);
    }
    if (actionData?.success === false || actionData?.data.success === false) {
      setIsDialogOpen(false);
      toast({
        title: "Error",
        description: actionData?.error ?? "An error occurred",
        variant: "destructive",
      });
    }
  }, [actionData]);

  const winnerToBeSynced = data?.limit_left;
  // TODO: Refactor how reward rules are calculated PERK-1411
  // data?.limit_left >= data?.eligible_participants_count
  //   ? data?.eligible_participants_count
  //   : data?.limit_left;

  return (
    <AlertDialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
      <AlertDialogTrigger asChild>
        <Button className="w-full" onClick={fetchPossibleWinnersToSync}>
          Sync Winners
        </Button>
      </AlertDialogTrigger>
      <AlertDialogContent className="max-w-xl">
        <AlertDialogHeader>
          {isLoading ? (
            <SkeletonLoader />
          ) : (
            <>
              <AlertDialogTitle>
                {`This reward has enough inventory for only ${data.limit_left} more winners.`}
              </AlertDialogTitle>
              <AlertDialogDescription className="flex flex-col gap-y-3">
                <span>
                  {`Only ${data.limit_left} can be assigned due to the current inventory balance.`}
                </span>
                <span>
                  Edit the Reward to increase the inventory limit, then award
                  all eligible users.
                </span>
              </AlertDialogDescription>
              <AlertDialogFooter>
                <div className="flex justify-between w-full mt-2">
                  <div className="flex items-center">
                    <Checkbox
                      className="rounded-xs"
                      checked={sendEmail}
                      onCheckedChange={(checkValue) =>
                        setSendEmail(!!checkValue)
                      }
                      aria-label="Select row"
                    />
                    <label className="ml-2 text-sm text-muted-foreground">
                      Send email to winners
                    </label>
                  </div>
                  <div className="flex gap-x-2">
                    <AlertDialogCancel>Cancel</AlertDialogCancel>
                    <Button
                      type="button"
                      onClick={() => {
                        navigate(`/admin/v2/rewards/${reward_id}/edit`);
                      }}
                    >
                      Edit Limit
                    </Button>
                    <SubmitButton
                      type="button"
                      submittingText="Syncing..."
                      onClick={() => {
                        syncWinnersSubmission(sendEmail);
                      }}
                      disabled={
                        data.limit_left === 0 ||
                        data.eligible_participants_count === 0
                      }
                    >
                      Sync {winnerToBeSynced} Winners
                    </SubmitButton>
                  </div>
                </div>
              </AlertDialogFooter>
            </>
          )}
        </AlertDialogHeader>
      </AlertDialogContent>
    </AlertDialog>
  );
}
