import { Button, SectionTitle, SWRDataTable } from "@bleu/ui";
import Link from "@components/Link";
import { PlusIcon } from "@radix-ui/react-icons";
import React from "react";
import { useParams } from "react-router-dom";

function AddProgramResourceLink({
  resourceName,
  to,
}: {
  resourceName: string;
  to: string;
}) {
  return (
    <Link to={to}>
      <Button size="sm" className="ml-auto hidden h-8 lg:flex text-sm">
        <PlusIcon className="mr-2 size-4" />
        Add {resourceName}
      </Button>
    </Link>
  );
}

export default function TermsAndRules() {
  const { program_id } = useParams();

  const url = `/admin/v2/programs/${program_id}/program_pages`;

  return (
    <>
      <div className="my-4 space-y-4">
        <div className="flex items-center justify-between space-y-2">
          <div>
            <SectionTitle>Program Pages</SectionTitle>
            <p className="text-muted-foreground">
              Here&apos;s a list of your custom pages. They will show in the
              program's landing page footer.
            </p>
          </div>
        </div>
        <SWRDataTable
          fetchPath={url}
          searchKey="title"
          action={
            <AddProgramResourceLink
              resourceName="Pages"
              to={`/admin/v2/programs/${program_id}/settings/program/program-pages/new`}
            />
          }
        />
      </div>
    </>
  );
}
