import {
  BaseField,
  Checkbox,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  withConditional,
} from "@bleu/ui";
import React from "react";

export interface WebhookEventFieldProps extends BaseField {
  type: "webhook_event";
}

export const WebhookEventField = withConditional<WebhookEventFieldProps>(
  ({ form, field }) => (
    <FormField
      control={form.control}
      name={field.name}
      render={({ field: formField }) => (
        <FormItem className="flex min-w-fit flex-row items-start space-x-3 space-y-0">
          <input
            type="hidden"
            hidden
            name={`${field.name}.event_type`}
            value={formField.value?.event_type || ""}
          />
          {formField.value?.id && (
            <input
              type="hidden"
              hidden
              name={`${field.name}.id`}
              value={formField.value?.id}
            />
          )}
          <FormControl>
            <Checkbox
              checked={!!formField.value?.event_type}
              onCheckedChange={(checked) => {
                if (checked) {
                  formField.onChange({
                    ...formField.value,
                    event_type: field.value,
                  });
                } else {
                  formField.onChange({
                    ...formField.value,
                    event_type: null,
                    _destroy: formField.value?.id ? 1 : undefined,
                  });
                }
              }}
            />
          </FormControl>
          <div className="space-y-1 leading-none">
            <FormLabel>{field.label}</FormLabel>
            <FormDescription>{field.description}</FormDescription>
          </div>
        </FormItem>
      )}
    />
  ),
);
