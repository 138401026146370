import { SWRDataTable } from "@bleu/ui";
import React from "react";
import { useParams } from "react-router-dom";

function TransactionsTable() {
  const { reward_id } = useParams();
  return (
    <SWRDataTable
      fetchPath={`/admin/v2/rewards/${reward_id}/blockchain_transactions`}
      action={null}
      searchKey="from"
    />
  );
}

export default TransactionsTable;
