/* eslint-disable no-restricted-syntax */
import {
  buildDataTableColumns,
  DataTable,
  DataTableBody,
  DataTableHeader,
  Table,
  useTableState,
} from "@bleu/ui";
import React from "react";

export function DashboardTable({ data }) {
  const { tableState, setTableState } = useTableState();

  const buildColumns = (tableColumns, tableFilters) =>
    buildDataTableColumns(tableColumns, tableFilters, []);

  return (
    <div className="border-2 rounded-lg">
      <DataTable
        data={data}
        error={null}
        tableState={tableState}
        setTableState={setTableState}
        setSelectedData={null}
        setQueryToParams={false}
        // @ts-expect-error TS(2339) FIXME: Property 'buildTableColumns' does not exist on type '{}'.
        buildTableColumns={buildColumns}
      >
        <Table>
          <DataTableHeader />
          <DataTableBody hasDetails={false} />
        </Table>
      </DataTable>
    </div>
  );
}
