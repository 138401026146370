import React from "react";

function ProgramPage({ page }) {
  return (
    <div
      //100vh - 80px (header) - 40px (padding top) - 95px (footer)
      className="mx-10 lg:mx-60 mt-20 mb-10 pt-10 min-h-[calc(100vh-80px-40px-95px)]"
      dangerouslySetInnerHTML={{ __html: page.content }}
    />
  );
}

const App = ({ page }) => {
  return (
    <React.StrictMode>
      <ProgramPage page={page} />
    </React.StrictMode>
  );
};

export default App;
