import { buildForm, Button, Form } from "@bleu/ui";
import { SubmitButton } from "@components/SubmitButton";
import Card from "@components/ui/Card";
import React from "react";
import { useForm } from "react-hook-form";

export function IntegrationConnect({
  title,
  fields,
  defaultValues,
  actionText = "Save",
  action = undefined,
  encType = undefined,
}) {
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const form = useForm({
    defaultValues,
  });

  return (
    <Card.Root className="max-w-full border dark:border-2 pb-0 shadow-sm bg-background">
      <Form
        {...form}
        action={action.path}
        method="post"
        encType={encType}
        onSubmit={() => setIsSubmitting(true)}
      >
        <Card.Content className="p-6">
          <Card.Title className="mt-0 p-0 text-xl font-semibold">
            {title}
          </Card.Title>
          <div className="mt-2 flex flex-wrap gap-4">
            {buildForm(fields, form)}
          </div>
        </Card.Content>
        <Card.Footer className="flex justify-between border-t dark:border-t-2 px-6 py-3 text-sm">
          <div className="flex space-x-4">
            <SubmitButton type="submit" isSubmitting={isSubmitting}>
              {actionText}
            </SubmitButton>
            <Button
              type="button"
              className="text-sm"
              variant="destructive"
              onClick={() => {
                form.reset();
              }}
            >
              Reset
            </Button>
          </div>
        </Card.Footer>
      </Form>
    </Card.Root>
  );
}
