import { SWRDataTable } from "@bleu/ui";
import { DownloadButton } from "@components/DownloadButton";
import React from "react";
import { useParams } from "react-router-dom";

function ActionsTable() {
  const { program_id } = useParams();
  const baseAPIPath = `/admin/v2/programs/${program_id}/dashboard/actions`;

  return (
    <div className="mb-3">
      <SWRDataTable
        fetchPath={baseAPIPath}
        defaultParams={{ pagination: { pageIndex: 0, pageSize: "20" } }}
        action={
          <DownloadButton url={`${baseAPIPath}.csv`} text="Export Table" />
        }
      />
    </div>
  );
}

export default ActionsTable;
