import { toast } from "@bleu/ui";
import { Spinner } from "@components/Spinner";
import { client } from "@utils/api-client";
import React, { useEffect, useState } from "react";
import {
  Await,
  defer,
  json,
  useActionData,
  useLoaderData,
  useNavigate,
  useParams,
  useSubmit,
} from "react-router-dom";

import { EndUserLayout } from "../Layout";
import { Article } from "./Article";
import { CallToAction } from "./CallToAction";
import { Collect } from "./Collect";
import { ChallengeData } from "./components/types";
import { Link } from "./Link";
import { Submitted } from "./Submitted";
import { Video } from "./Video";

interface ActionData {
  data: {
    success: boolean;
    message?: string;
  };
}

const action = async ({ request }) => {
  const formData = await request.formData();

  const data = await client("/challenge/submit", {
    body: formData,
    method: "POST",
  });

  return json({ data });
};

const loader = async ({ params }) => {
  const { challenge_id } = params;
  const data = client(`/challenge/details/${challenge_id}`);
  return defer({ data });
};

const CHALLENGE_COMPONENTS = {
  call_action: CallToAction,
  link: Link,
  article: Article,
  video: Video,
  collect: Collect,
} as const;

function ChallengeContent({
  data,
  handleSubmit,
  isSubmitted,
}: {
  data: ChallengeData;
  handleSubmit: (customData?: FormData) => void;
  isSubmitted: boolean;
}) {
  if (isSubmitted && data.challenge_type !== "video") {
    return <Submitted successMessage={data.success_message} />;
  }

  const ChallengeComponent =
    CHALLENGE_COMPONENTS[
      data.challenge_type as keyof typeof CHALLENGE_COMPONENTS
    ];

  if (!ChallengeComponent) {
    return null;
  }

  return <ChallengeComponent data={data} handleSubmit={handleSubmit} />;
}

export default function ChallengePage() {
  const [loading, setLoading] = useState(true);
  const { data } = useLoaderData() as { data: Promise<ChallengeData> };
  const actionData = useActionData() as ActionData;
  const [isSubmitted, setIsSubmitted] = useState(false);
  const navigate = useNavigate();
  const params = useParams();
  const submit = useSubmit();

  useEffect(() => {
    if (actionData?.data?.success) {
      setIsSubmitted(true);
    } else if (!actionData?.data?.success && actionData?.data?.message) {
      toast({
        title: actionData?.data?.message || "Something went wrong",
        description: "Please try again",
        variant: "destructive",
      });
    }
  }, [actionData]);

  const handleSubmit = async (customData?: FormData) => {
    const formData = customData || new FormData();

    if (params && params.challenge_id) {
      formData.append("challenge_id", params.challenge_id);

      submit(formData, { method: "post", encType: "multipart/form-data" });
    }
  };

  useEffect(() => {
    data.then((resolvedData) => {
      if (
        resolvedData?.bypass_modal &&
        resolvedData?.link_url &&
        !resolvedData?.is_repeatable_and_completed
      ) {
        window.location.href = resolvedData.link_url;
      } else {
        setLoading(false);
      }
      if (resolvedData?.current_participant === null) {
        const path = `/signin?redirectTo=${location.pathname}${location.search}`;
        navigate(path);
      }
    });
  }, [data, navigate]);

  function Loading() {
    return (
      <div className="flex justify-center items-center flex-col w-full">
        <Spinner />
        <p className="mt-4 text-lg">Loading, please wait...</p>
      </div>
    );
  }

  return (
    <div>
      {!loading && (
        <div className="fixed inset-0 z-[1400] bg-black/60 backdrop-blur-[1px]"></div>
      )}
      <EndUserLayout>
        {loading ? (
          <Loading />
        ) : (
          <React.Suspense fallback={<Loading />}>
            <Await resolve={data} errorElement={<p>Error loading challenge</p>}>
              {(resolvedData) => (
                <ChallengeContent
                  data={resolvedData}
                  handleSubmit={handleSubmit}
                  isSubmitted={isSubmitted}
                />
              )}
            </Await>
          </React.Suspense>
        )}
      </EndUserLayout>
    </div>
  );
}

ChallengePage.loader = loader;
ChallengePage.action = action;
