import { Button } from "@bleu/ui";
import Link from "@components/Link";
import { useProgram } from "@contexts/ProgramContext";
import {
  buildIndexPage,
  buildIndexPageActionButton,
} from "@lib/pageBuilder/pagesHelper";
import { ReaderIcon } from "@radix-ui/react-icons";
import React from "react";

function IntegrationApiAuth() {
  const { id } = useProgram();

  const docsUrl =
    process.env.NODE_ENV === "production"
      ? "https://perk.studio/api/docs"
      : "http://127.0.0.1:3000/api/docs";

  const { element: ApiAuthTable } = buildIndexPage({
    modelName: "API Keys",
    fetchPath: "/admin/v2/programs/:program_id/api_auths",
    action: <Actions />,
    searchKey: "name",
  });

  function Actions() {
    return (
      <div className="flex gap-x-2">
        <Link to={docsUrl}>
          <Button size="sm" className="ml-auto hidden h-8 lg:flex">
            <ReaderIcon className="mr-2 size-4" />
            Go to API Docs
          </Button>
        </Link>
        {buildIndexPageActionButton({
          link: `/admin/v2/programs/${id}/settings/integrations/api_auths/new`,
          text: "Add API Key",
        })}
      </div>
    );
  }
  return <>{ApiAuthTable}</>;
}

export default IntegrationApiAuth;
