import Button from "@components/ui/Button";
import { useMediaDuration } from "@hooks/useMediaDuration";
import {
  CheckIcon,
  Cross2Icon,
  ExternalLinkIcon,
  ThickArrowLeftIcon,
  ThickArrowRightIcon,
} from "@radix-ui/react-icons";
import { formatDate } from "@utils/formatDate";
import { cn } from "@utils/mergeClassNames";
import React, { useEffect, useRef, useState } from "react";
import { SocialIcon } from "react-social-icons";
import { useSwipeable } from "react-swipeable";

const IndexIndicator = ({ total, currentIndex }) => {
  return (
    <div className="absolute inset-x-0 bottom-2 flex justify-center">
      <div className="mt-2 flex space-x-2">
        {Array.from({ length: total }, (_, index) => (
          <div
            key={index}
            className={cn("h-2 w-2 rounded-full", {
              "bg-white": index === currentIndex,
              "bg-gray-400": index !== currentIndex,
            })}
          ></div>
        ))}
      </div>
    </div>
  );
};

const DisplayMedia = ({
  postType,
  media,
  description,
  videoRef,
  isPlaying,
  handleNextMedia,
  handlePreviousMedia,
}) => {
  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => handleNextMedia(),
    onSwipedRight: () => handlePreviousMedia(),
    // @ts-expect-error TS(2345) FIXME: Argument of type '{ onSwipedLeft: () => any; onSwi... Remove this comment to see the full error message
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  useEffect(() => {
    const video = videoRef.current;
    if (!video || isPlaying) return;
    if (video.paused && !isPlaying) {
      video.play().catch(() => {
        // Auto-play was prevented. Mute and play.
        video.muted = true;
        video.play().catch(() => {
          // Auto-play was prevented. Show a "Play"
          // button so that user can start playback.
          video.controls = true;
        });
      });
    }
  }, []);

  if (postType === "image") {
    return (
      <img
        {...swipeHandlers}
        src={media.media_path}
        loading="eager"
        className="absolute inset-0 size-full object-contain"
      />
    );
  }

  if (postType === "video") {
    return (
      <div {...swipeHandlers} className="absolute inset-0 size-full">
        <video ref={videoRef} controls autoPlay className="size-full">
          <source src={media.media_path} />
          <p>This browser does not support the video element.</p>
        </video>
      </div>
    );
  }

  return (
    <div className="flex size-full items-center justify-center bg-gray-100 p-3">
      <div className="max-h-80 overflow-auto p-1 md:max-h-[30rem]">
        <p className="text-left text-lg text-gray-800">{description}</p>
      </div>
    </div>
  );
};

const MediaControls = ({
  media,
  currentMediaIndex,
  handleNextMedia,
  handlePreviousMedia,
}) => {
  if (media.length <= 1) {
    return null;
  }

  return (
    <>
      <IndexIndicator total={media.length} currentIndex={currentMediaIndex} />
      <div className="absolute top-1/2 flex w-full -translate-y-1/2 justify-between">
        <button
          className="ml-2 rounded-full bg-gray-100/60 px-2 py-0 text-gray-500 hover:bg-gray-200 hover:text-gray-500"
          onClick={handlePreviousMedia}
        >
          &#8249;
        </button>
        <button
          className="mr-2 rounded-full bg-gray-100/60 px-2 py-0 text-gray-500 hover:bg-gray-200 hover:text-gray-500"
          onClick={handleNextMedia}
        >
          &#8250;
        </button>
      </div>
    </>
  );
};

const TimedNavigationButtons = ({
  time,
  earned,
  isVideo,
  isPlaying,
  points,
  handlePrevious,
  handleNext,
  isPostGallery,
}) => {
  const [counter, setCounter] = useState(time);
  const [isNextEnabled, setIsNextEnabled] = useState(false);
  const [showPoints, setShowPoints] = useState(!earned);
  const [pointsVisible, setPointsVisible] = useState(false);

  useEffect(() => {
    setCounter(time);
  }, [time]);

  useEffect(() => {
    if (isVideo && !isPlaying && !isPostGallery) {
      setCounter(time);
      return;
    }
    if (counter > 0) {
      const timer = setTimeout(() => {
        setCounter(counter - 1);
      }, 1000);
      return () => clearTimeout(timer);
    } else if (counter !== null && typeof counter === "number") {
      setIsNextEnabled(true);
      if (showPoints) {
        setPointsVisible(true);
      }
      setShowPoints(false);
    }
  }, [counter, isPlaying]);

  return (
    <div className="flex flex-col items-start justify-start gap-1">
      <div
        className={cn("text-sm font-extrabold text-black", {
          "animate-fade-in-up opacity-100": pointsVisible,
          "opacity-0": !pointsVisible,
        })}
      >
        +{points} points earned
      </div>
      {earned && (
        <div className="animate-fade-in-up flex items-center justify-start text-sm font-extrabold text-black">
          <p className="m-0">+{points}</p>
          <CheckIcon />
        </div>
      )}
      <div className="flex gap-2">
        {/* @ts-expect-error TS(2322) FIXME: Type '{ children: Element; onClick: any; }' is not... Remove this comment to see the full error message */}
        <Button onClick={handlePrevious}>
          <ThickArrowLeftIcon className="text-perk-primary-content fill-current" />
        </Button>
        {/* @ts-expect-error TS(2322) FIXME: Type '{ children: Element; onClick: any; disabled:... Remove this comment to see the full error message */}
        <Button onClick={handleNext} disabled={!isNextEnabled}>
          {isNextEnabled ? (
            <div className="flex items-center gap-1">
              <p className="text-perk-primary-content m-0 items-center p-0">
                Next post
              </p>
              <ThickArrowRightIcon className="text-perk-primary-content fill-current" />
            </div>
          ) : (
            <p className="text-perk-primary-content m-0 items-center p-0">
              {counter || ""}
            </p>
          )}
        </Button>
      </div>
    </div>
  );
};

const SocialMediaPost = ({
  post,
  postViewed,
  handleNextPost,
  handlePreviousPost,
  handleSubmitViewedPost,
  points,
}) => {
  const { description, owner, postUrl, media, publishedAt, pageUrl } = post;
  const [currentMediaIndex, setCurrentMediaIndex] = useState(0);
  const videoRef = useRef(null);
  const currentPostType = media[currentMediaIndex].post_type || "text";
  const isGallery = media.length > 1;
  const { timeToNext, isPlaying } = useMediaDuration(
    videoRef,
    currentPostType,
    isGallery,
  );

  useEffect(() => {
    setCurrentMediaIndex(0);
  }, [media]);

  const handleNextMedia = () => {
    setCurrentMediaIndex((prevIndex) => (prevIndex + 1) % media.length);
  };

  const handlePreviousMedia = () => {
    setCurrentMediaIndex(
      (prevIndex) => (prevIndex - 1 + media.length) % media.length,
    );
  };

  const handleModalClose = () => {
    handleSubmitViewedPost();
    // @ts-expect-error TS(2322) FIXME: Type 'string' is not assignable to type '(string |... Remove this comment to see the full error message
    window.location = "/";
  };

  const user = owner || `@${pageUrl?.split("/").pop()}`;

  return (
    <div className="mx-10 flex h-5/6 w-full flex-col gap-1 overflow-hidden md:mx-auto md:w-3/4 md:flex-row">
      <div className="h-[25rem] w-full md:h-auto md:w-1/2">
        <div className="relative size-full object-cover">
          <DisplayMedia
            postType={currentPostType}
            media={media[currentMediaIndex]}
            description={description}
            videoRef={videoRef}
            isPlaying={isPlaying}
            handleNextMedia={handleNextMedia}
            handlePreviousMedia={handlePreviousMedia}
          />
          <MediaControls
            media={media}
            currentMediaIndex={currentMediaIndex}
            handleNextMedia={handleNextMedia}
            handlePreviousMedia={handlePreviousMedia}
          />
        </div>
      </div>
      <div className="relative h-auto w-full bg-white p-3 md:h-[37rem] md:w-2/5">
        <button
          className="absolute right-4 p-1 first-letter:top-4 hover:rounded-full hover:bg-gray-200 focus:outline-none"
          onClick={handleModalClose}
        >
          <Cross2Icon fill="currentColor" />
        </button>
        <div className="flex h-full flex-col justify-between">
          <div className="flex h-auto flex-col overflow-y-scroll">
            <div className="group flex flex-col">
              <a href={postUrl} target="_blank">
                <div className="flex items-center justify-start text-black">
                  <SocialIcon
                    url={postUrl}
                    style={{ height: 25, width: 25 }}
                    target="_blank"
                  />
                  <p className="mx-2 mb-0 text-lg font-bold">{user}</p>
                  <ExternalLinkIcon className="group-hover:stroke-black" />
                </div>
                <p className="mt-1 text-sm text-gray-500">
                  {formatDate(publishedAt)}
                </p>
              </a>
            </div>
            {currentPostType !== "text" && (
              <div className="overflow-y-scroll bg-gradient-to-b text-left text-sm text-gray-500">
                {description}
              </div>
            )}
          </div>
          <TimedNavigationButtons
            points={points}
            time={postViewed ? 0 : timeToNext}
            earned={postViewed}
            isVideo={currentPostType === "video"}
            isPlaying={isPlaying}
            isPostGallery={isGallery}
            handleNext={handleNextPost}
            handlePrevious={handlePreviousPost}
          />
        </div>
      </div>
    </div>
  );
};

const PostsCompletionMessage = () => {
  return (
    <div className="md:min-w-screen flex min-h-screen min-w-full items-center justify-center bg-[#252525]">
      <div className="mx-auto flex size-auto flex-col items-center justify-center gap-2 rounded-sm bg-white p-5">
        <p className="text-center text-2xl font-bold text-gray-600">
          It looks like you've already viewed all the posts, come back here
          later
        </p>
        <button
          className="text-bold text-perk-primary-content bg-perk-primary rounded-sm px-3 py-2 text-base"
          onClick={() => {
            // @ts-expect-error TS(2322) FIXME: Type 'string' is not assignable to type '(string |... Remove this comment to see the full error message
            window.location = "/";
          }}
        >
          Go to challenges
        </button>
      </div>
    </div>
  );
};

const UnifiedSocialPosts = ({
  data: posts,
  challenge_id,
  challenge_points,
}) => {
  const [currentPostIndex, setCurrentPostIndex] = useState(0);
  useState(0);
  const [viewedPosts, setViewedPosts] = useState(
    Array(posts.length).fill(false),
  );

  if (posts.length === 0 || viewedPosts.every((viewed) => viewed === true)) {
    return <PostsCompletionMessage />;
  }

  const handleSubmitViewedPost = () => {
    const baseUrl = window.location.origin;
    const url = `${baseUrl}/participants/challenges/${challenge_id}/unified_social_submission?social_network_post_id=${posts[currentPostIndex].id}`;

    fetch(url, {
      method: "POST",
      // @ts-expect-error TS(2304) FIXME: Cannot find name 'ReactOnRails'.
      headers: ReactOnRails.authenticityHeaders(),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      // eslint-disable-next-line no-console
      .then((data) => console.log(data))
      // eslint-disable-next-line no-console
      .catch((error) => console.log(error));
  };

  const handleNextPost = async () => {
    handleSubmitViewedPost();
    setViewedPosts((prevViewedPosts) => {
      const newViewedPosts = [...prevViewedPosts];
      newViewedPosts[currentPostIndex] = true;
      return newViewedPosts;
    });
    setCurrentPostIndex((prevIndex) => (prevIndex + 1) % posts.length);
  };

  const handlePreviousPost = () => {
    setCurrentPostIndex(
      (prevIndex) => (prevIndex - 1 + posts.length) % posts.length,
    );
  };

  const currentPost = posts[currentPostIndex];
  // media is the main post + all the child posts
  const medias = [
    {
      post_type: currentPost.post_type,
      media_path: currentPost.media_path,
    },
    ...currentPost.social_network_child_posts,
  ];

  const post = {
    description: currentPost.message,
    owner: currentPost.username,
    postUrl: currentPost.url,
    media: medias,
    publishedAt: currentPost.published_at,
    pageUrl: currentPost.social_network_page.url,
  };

  return (
    <div className="md:min-w-screen flex min-h-screen min-w-full items-center justify-center bg-[#252525]">
      <SocialMediaPost
        key={currentPostIndex}
        post={post}
        postViewed={viewedPosts[currentPostIndex]}
        points={challenge_points}
        handleNextPost={handleNextPost}
        handlePreviousPost={handlePreviousPost}
        handleSubmitViewedPost={handleSubmitViewedPost}
      />
    </div>
  );
};

export default UnifiedSocialPosts;
