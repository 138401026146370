import { Button } from "@bleu/ui";
import { Cross1Icon } from "@radix-ui/react-icons";
import React from "react";

export function CloseButton() {
  return (
    <Button
      className="bg-transparent shadow-none w-fit p-2 outline-perk-primary"
      onClick={() => {
        window.location.href = "/";
      }}
    >
      <Cross1Icon className="size-3 text-black" />
    </Button>
  );
}
