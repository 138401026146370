import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@bleu/ui";
import Link from "@components/Link";
import { ChevronDownIcon } from "@radix-ui/react-icons";
import { cn } from "@utils/mergeClassNames";
import React from "react";
import { useLocation } from "react-router-dom";

const linkItems = (program_id) => {
  return [
    { path: `/admin/v2/programs/${program_id}/dashboard`, name: "Dashboard" },
    {
      path: `/admin/v2/programs/${program_id}/users`,
      name: "Users",
    },
    { path: `/admin/v2/programs/${program_id}/rewards`, name: "Rewards" },
    {
      path: `/admin/v2/programs/${program_id}/challenges`,
      name: "Challenges",
    },
  ];
};

const settingsMenuItems = (program_id) => {
  return [
    {
      path: `/admin/v2/programs/${program_id}/settings/program`,
      name: "Configuration",
    },
    {
      path: `/admin/v2/programs/${program_id}/settings/layout/navigation-menus`,
      name: "Layout",
    },
    {
      path: `/admin/v2/programs/${program_id}/settings/branding`,
      name: "Branding",
    },
    {
      path: `/admin/v2/programs/${program_id}/settings/integrations`,
      name: "Integrations",
    },
  ];
};

export function MainNav({ className, program, ...props }) {
  const pathname = useLocation().pathname;
  return (
    <nav
      className={cn("flex items-center space-x-4 lg:space-x-6", className)}
      {...props}
    >
      {linkItems(program.id).map((item, idx) => (
        <Link
          key={idx}
          to={item.path}
          className={cn(
            "text-muted-foreground hover:text-primary text-sm font-medium transition-colors",
            pathname === item.path
              ? "text-primary underline underline-offset-2"
              : "",
          )}
        >
          {item.name}
        </Link>
      ))}
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <button
            // @ts-expect-error TS(2322) FIXME: Type '{ children: Element[]; variant: string; clas... Remove this comment to see the full error message
            variant="outline"
            className={cn(
              "text-muted-foreground hover:text-primary ring-offset-background inline-flex items-center border-0 text-sm font-medium outline-none ring-0 transition-colors focus:border-0 focus:outline-none focus:ring-0 focus-visible:ring-0",
              pathname.includes("settings")
                ? "text-primary underline underline-offset-2"
                : "",
            )}
          >
            <span className="mr-1">Settings</span>
            <ChevronDownIcon />
          </button>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="w-36">
          <DropdownMenuGroup>
            {settingsMenuItems(program.id).map((item, idx) => (
              <Link key={idx} to={item.path}>
                <DropdownMenuItem key={item.path}>
                  <span>{item.name}</span>
                </DropdownMenuItem>
              </Link>
            ))}
          </DropdownMenuGroup>
        </DropdownMenuContent>
      </DropdownMenu>
    </nav>
  );
}
