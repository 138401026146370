import {
  capitalize,
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
  cn,
  Separator,
} from "@bleu/ui";
import Link from "@components/Link";
import Card from "@components/ui/Card";
import { CalendarIcon, PersonIcon, SewingPinIcon } from "@radix-ui/react-icons";
import { formatDate } from "@utils/formatDate";
import Autoplay from "embla-carousel-autoplay";
import React from "react";
import { useParams } from "react-router-dom";

const commonPattern =
  "pattern-border pattern-bg-background pattern-size-4 pattern-opacity-50";
const bgPatterns = [
  "pattern-triangles " + commonPattern,
  "pattern-diagonal-lines " + commonPattern,
  "pattern-zigzag pattern-size-6 " + commonPattern,
];

function CarouselUserItem({
  user,
  index,
  programId,
  className,
}: {
  user: {
    id: string;
    name: string;
    created_at: string;
    ahoy_city: string;
    ahoy_country: string;
  };
  index: number;
  programId: string;
  className?: string;
}) {
  const backgroundClass = bgPatterns[index % bgPatterns.length];

  return (
    <CarouselItem
      className={cn(
        "md:basis-1/2 lg:basis-1/3 border-2 rounded-lg pl-0",
        className,
      )}
    >
      <Card.Root className="relative w-full h-[300px] p-0 m-0 shadow-none bg-background">
        <div className={`absolute size-full ${backgroundClass}`} />
        <Card.Content className="flex flex-col justify-between h-full p-5">
          <UserDetails user={user} programId={programId} />
          <UserMetadata user={user} />
        </Card.Content>
      </Card.Root>
    </CarouselItem>
  );
}

function UserDetails({ user, programId }) {
  return (
    <div className="flex flex-col items-start mb-4 gap-y-1">
      <PersonIcon className="text-gold size-6 z-50" />
      <Link
        className="text-xl font-semibold text-foreground underline z-50 underline-offset-2 text-wrap"
        to={`/admin/v2/programs/${programId}/users/${user.id}`}
      >
        {capitalize(user.name)}
      </Link>
    </div>
  );
}

function UserMetadata({ user }) {
  return (
    <div>
      {user?.country && (
        <LocationDetail city={user.ahoy_city} country={user.ahoy_country} />
      )}
      <div className="flex items-center space-x-2 mb-2">
        <CalendarIcon className="z-50 text-gold" />
        <span className="text-foreground z-50 text-sm font-light">
          {formatDate(user.created_at)}
        </span>
      </div>
    </div>
  );
}

function LocationDetail({ city, country }) {
  return (
    <div className="flex items-center space-x-2 mb-2">
      <SewingPinIcon className="z-50 text-gold" />
      <span className="text-foreground z-50 text-sm font-light">
        {city && `${capitalize(city)}, `}
        {capitalize(country)}
      </span>
    </div>
  );
}

export function DashboardLatestUsers({ data }) {
  const plugin = React.useRef(
    Autoplay({ delay: 4000, stopOnInteraction: true, playOnInit: true }),
  );
  const { program_id } = useParams();

  return (
    <div className="flex flex-col gap-y-2">
      <div className="flex items-center gap-x-2">
        <h2 className="text-2xl font-bold whitespace-nowrap">Latest Users</h2>
        <div className="grow">
          <Separator />
        </div>
      </div>
      <Carousel
        plugins={[plugin.current]}
        className="size-full"
        opts={{ align: "start", loop: true }}
      >
        <CarouselContent className="gap-3 ml-2">
          {data.length > 0 ? (
            data.map((user, index) => (
              <CarouselUserItem
                key={user.id}
                user={user}
                index={index}
                programId={program_id}
                className={index === data.length - 1 && "mr-4"}
              />
            ))
          ) : (
            <CarouselItem>
              <div className="w-full text-center h-[300px] flex items-center justify-center">
                No results found.
              </div>
            </CarouselItem>
          )}
        </CarouselContent>
        <CarouselNext className="-right-0" />
        <CarouselPrevious className="left-4" />
      </Carousel>
    </div>
  );
}
