import { SWRDataTable } from "@bleu/ui";
import React from "react";
import { useParams } from "react-router-dom";

function ParticipantRewardsTable() {
  const { end_user_id } = useParams();

  return (
    <SWRDataTable
      fetchPath={`/admin/v2/participants/${end_user_id}/rewards`}
      defaultParams={{
        columnVisibility: {
          tags: false,
          details_url: false,
          actions: false,
        },
      }}
    />
  );
}

export default ParticipantRewardsTable;
