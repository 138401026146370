import "@components/RichTextEditor/styles.css";
import "jodit/es5/jodit.min.css";

import { i18n, RailsAppProvider, ThemeProvider } from "@bleu/ui";
import { ProgramProvider, useProgram } from "@contexts/ProgramContext";
import { RailsFlashProvider } from "@contexts/RailsFlashContext";
import { UserProvider } from "@contexts/UserContext";
import {
  buildEditPage,
  buildIndexPage,
  buildIndexPageActionButton,
  buildNewPage,
} from "@lib/pageBuilder/pagesHelper";
import { LayoutSettingsSidebar } from "@pages/admin/organization/programs/settings/layout/(components)/LayoutSettingsSidebar";
import ProgramCarouselsSetting from "@pages/admin/organization/programs/settings/layout/carousels";
import ProgramHeaderSetting from "@pages/admin/organization/programs/settings/layout/navigation-menus";
import React, { useEffect } from "react";
import { I18nextProvider } from "react-i18next";
import ReactOnRails from "react-on-rails";
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from "react-router-dom";

import LoginPage from "./(components)/Login";
import { organizationSidebar } from "./(components)/SidebarNav";
import ChallengesPage from "./challenges";
import ChallengeDetailsPage from "./challenges/details";
import ChallengeAnswersTable from "./challenges/details/participant_answers";
import ChallengeUserTable from "./challenges/details/users";
import EditChallengePage from "./challenges/edit";
import NewChallenge from "./challenges/new";
import DashboardPage from "./dashboard";
import ActionsTable from "./dashboard/actions";
import ChallengesTable from "./dashboard/challenges";
import DashboardOverviewPage from "./dashboard/overview";
import RewardsTable from "./dashboard/rewards";
import Layout, { WithSidebarLayout } from "./Layout";
import OrganizationAdminsPage from "./organization/admins";
import ProgramsPage from "./organization/programs";
import ProgramBrandingLayout from "./organization/programs/settings/branding/(components)/Layout";
import ProgramAssetsSettingPage from "./organization/programs/settings/branding/assets";
import ProgramBordersSettingPage from "./organization/programs/settings/branding/borders";
import ProgramBrandingColorsPage from "./organization/programs/settings/branding/colors";
import CustomCSSSetting from "./organization/programs/settings/branding/css";
import ProgramFontsSettingPage from "./organization/programs/settings/branding/fonts";
import ProgramBrandingThemePage from "./organization/programs/settings/branding/theme";
import { IntegrationsSidebar } from "./organization/programs/settings/integrations/(components)/IntegrationsSidebar";
import IntegrationApiAuth from "./organization/programs/settings/integrations/api_auth";
import IntegrationApiAuthNew from "./organization/programs/settings/integrations/api_auth/new";
import IntegrationAptos from "./organization/programs/settings/integrations/aptos";
import IntegrationOffersReward from "./organization/programs/settings/integrations/offers-reward";
import IntegrationPhotoContests from "./organization/programs/settings/integrations/photo-contests";
import IntegrationSocialNetworkPages from "./organization/programs/settings/integrations/social_network_pages";
import { SettingsSidebar } from "./organization/programs/settings/program/(components)/SettingsSidebar";
import ProgramEnrollmentSetting from "./organization/programs/settings/program/enrollment";
import ProgramGeneralSetting from "./organization/programs/settings/program/general";
import ProgramLabelSetting from "./organization/programs/settings/program/labels";
import ProgramProfileAttributesSetting from "./organization/programs/settings/program/profile_attributes";
import EditProfileAttributePage from "./organization/programs/settings/program/profile_attributes/edit";
import NewProfileAttributePage from "./organization/programs/settings/program/profile_attributes/new";
import ProgramPages from "./organization/programs/settings/program/program_pages";
import ProgramPagesNew from "./organization/programs/settings/program/program_pages/new";
import SEOSetting from "./organization/programs/settings/program/seo";
import ParticipantsPage from "./participants";
import ParticipantDetailsPage from "./participants/details";
import ParticipantActionsTable from "./participants/details/actions";
import ParticipantAttributesTable from "./participants/details/attributes";
import ParticipantTransactionsTable from "./participants/details/blockchain_transactions";
import ParticipantChallengesTable from "./participants/details/challenges";
import ParticipantAnswersTable from "./participants/details/participant_answers";
import ParticipantQuestionsTable from "./participants/details/questions";
import ParticipantRewardsTable from "./participants/details/rewards";
import RewardsPage from "./rewards";
import RewardDetailsPage from "./rewards/details";
import TransactionsTable from "./rewards/details/blockchain_transactions";
import RewardsCouponsTable from "./rewards/details/coupons";
import MiniContestUserTable from "./rewards/details/mini_contest";
import SweepstakesUserTable from "./rewards/details/sweepstakes";
import RewardsUserTable from "./rewards/details/users";
import EditRewardPage from "./rewards/edit";
import NewRewardPage from "./rewards/new";

const IndexRedirect = () => {
  const program = useProgram();
  return (
    <Navigate
      to={`/admin/v2/programs/${program.id}/dashboard`}
      replace={true}
    />
  );
};

const ProgramPagesRedirect = () => {
  const program = useProgram();
  return (
    <Navigate
      to={`/admin/v2/programs/${program.id}/settings/program/program-pages`}
      replace={true}
    />
  );
};

const ProfileAttributesRedirect = () => {
  const program = useProgram();
  return (
    <Navigate
      to={`/admin/v2/programs/${program.id}/settings/program/profile-attributes`}
      replace={true}
    />
  );
};

const router = createBrowserRouter([
  {
    path: "/admin/v2/login",
    element: <LoginPage />,
  },
  {
    path: "/admin/v2",
    element: <Layout />,
    children: [
      {
        index: true,
        element: <IndexRedirect />,
      },
      {
        path: "organization",
        element: <WithSidebarLayout items={organizationSidebar} />,
        children: [
          {
            path: "programs",
            element: <ProgramsPage />,
          },
          {
            path: "admins",
            element: <OrganizationAdminsPage />,
            action: OrganizationAdminsPage.action,
            loader: OrganizationAdminsPage.loader,
          },
        ],
      },
      {
        path: "challenges",
        children: [
          {
            path: ":challenge_id",
            element: <ChallengeDetailsPage />,
            loader: ChallengeDetailsPage.loader,
            action: ChallengeDetailsPage.action,
            children: [
              {
                index: true,
                element: <ChallengeUserTable />,
              },
              {
                path: "participant_answers",
                element: <ChallengeAnswersTable />,
              },
            ],
          },
        ],
      },
      {
        path: "challenges/:challenge_id/edit",
        element: <EditChallengePage />,
        loader: EditChallengePage.loader,
        action: EditChallengePage.action,
      },
      {
        path: "rewards",
        children: [
          {
            path: ":reward_id",
            element: <RewardDetailsPage />,
            loader: RewardDetailsPage.loader,
            action: RewardDetailsPage.action,
            children: [
              {
                index: true,
                element: <RewardsUserTable />,
              },
              {
                path: "sweepstakes",
                element: <SweepstakesUserTable />,
                action: SweepstakesUserTable.action,
              },
              {
                path: "coupons",
                element: <RewardsCouponsTable />,
              },
              {
                path: "blockchain_transactions",
                element: <TransactionsTable />,
              },
              {
                path: "mini_contest",
                element: <MiniContestUserTable />,
                action: MiniContestUserTable.action,
              },
            ],
          },
        ],
      },
      {
        path: "rewards/:reward_id/edit",
        element: <EditRewardPage />,
        loader: EditRewardPage.loader,
        action: EditRewardPage.action,
      },
      {
        path: "program_pages",
        element: <SettingsSidebar />,
        children: [
          {
            index: true,
            element: <ProgramPagesRedirect />,
          },
          {
            path: ":program_page_id/edit",
            ...buildEditPage({
              modelName: "Program Page",
              actionPath: "/admin/v2/program_pages/:program_page_id",
              loaderPath: "/admin/v2/program_pages/:program_page_id/edit",
            }),
          },
        ],
      },
      {
        path: "profile_attributes",
        element: <SettingsSidebar />,
        children: [
          {
            index: true,
            element: <ProfileAttributesRedirect />,
          },
          {
            path: ":profile_attribute_id/edit",
            ...buildEditPage({
              modelName: "Program Page",
              actionPath: "/admin/v2/profile_attributes/:profile_attribute_id",
              loaderPath:
                "/admin/v2/profile_attributes/:profile_attribute_id/edit",
              redirectTo: true,
            }),
          },
        ],
      },
      {
        path: "programs/:program_id",
        children: [
          {
            index: true,
            element: <Navigate to="./dashboard" />,
          },
          {
            path: "dashboard",
            element: <DashboardPage />,
            loader: DashboardPage.loader,
            children: [
              {
                index: true,
                element: <DashboardOverviewPage />,
                loader: DashboardOverviewPage.loader,
              },
              {
                path: "actions",
                element: <ActionsTable />,
              },
              {
                path: "challenges",
                element: <ChallengesTable />,
              },
              {
                path: "rewards",
                element: <RewardsTable />,
              },
            ],
          },
          {
            path: "users",
            children: [
              {
                index: true,
                element: <ParticipantsPage />,
                action: ParticipantsPage.action,
              },
              {
                path: ":end_user_id",
                element: <ParticipantDetailsPage />,
                action: ParticipantDetailsPage.action,
                loader: ParticipantDetailsPage.loader,
                children: [
                  {
                    index: true,
                    element: <ParticipantActionsTable />,
                    // @ts-expect-error TS(2339) FIXME: Property 'loader' does not exist on type '() => El... Remove this comment to see the full error message
                    loader: ParticipantActionsTable.loader,
                  },
                  {
                    path: "rewards",
                    element: <ParticipantRewardsTable />,
                  },
                  {
                    path: "challenges",
                    element: <ParticipantChallengesTable />,
                  },
                  {
                    path: "questions",
                    element: <ParticipantQuestionsTable />,
                  },
                  {
                    path: "blockchain_transactions",
                    element: <ParticipantTransactionsTable />,
                  },
                  {
                    path: "attributes",
                    element: <ParticipantAttributesTable />,
                  },
                  {
                    path: "participant_answers",
                    element: <ParticipantAnswersTable />,
                  },
                ],
              },
            ],
          },
          {
            path: "challenges",
            children: [
              {
                index: true,
                element: <ChallengesPage />,
              },
              {
                path: "new",
                element: <NewChallenge />,
                loader: NewChallenge.loader,
                action: NewChallenge.action,
              },
            ],
          },
          {
            path: "rewards",
            children: [
              {
                index: true,
                element: <RewardsPage />,
              },
              {
                path: "new",
                element: <NewRewardPage />,
                loader: NewRewardPage.loader,
                action: NewRewardPage.action,
              },
            ],
          },
          {
            path: "settings",
            children: [
              {
                index: true,
                element: <Navigate to="./program" />,
              },
              {
                path: "program",
                element: <SettingsSidebar />,
                children: [
                  {
                    index: true,
                    element: <Navigate to="general" replace />,
                  },
                  {
                    path: "general",
                    element: <ProgramGeneralSetting />,
                  },
                  {
                    path: "profile-attributes",
                    children: [
                      {
                        index: true,
                        element: <ProgramProfileAttributesSetting />,
                      },
                      {
                        path: "new",
                        element: <NewProfileAttributePage />,
                        loader: NewProfileAttributePage.loader,
                        action: NewProfileAttributePage.action,
                      },
                    ],
                  },
                  {
                    path: "seo",
                    element: <SEOSetting />,
                  },
                  {
                    path: "program-pages",
                    children: [
                      { index: true, element: <ProgramPages /> },
                      {
                        path: "new",
                        element: <ProgramPagesNew />,
                        loader: ProgramPagesNew.loader,
                        action: ProgramPagesNew.action,
                      },
                    ],
                  },
                  {
                    path: "labels",
                    element: <ProgramLabelSetting />,
                  },
                  {
                    path: "enrollment",
                    element: <ProgramEnrollmentSetting />,
                  },
                ],
              },
              {
                path: "layout",
                element: <LayoutSettingsSidebar />,
                children: [
                  {
                    index: true,
                    element: <Navigate to="navigation-menus" replace />,
                  },
                  {
                    path: "navigation-menus",
                    element: <ProgramHeaderSetting />,
                    loader: ProgramHeaderSetting.loader,
                    action: ProgramHeaderSetting.action,
                  },
                  {
                    path: "carousels",
                    element: <ProgramCarouselsSetting />,
                    loader: ProgramCarouselsSetting.loader,
                    action: ProgramCarouselsSetting.action,
                  },
                ],
              },
              {
                path: "branding",
                element: <ProgramBrandingLayout />,
                loader: ProgramBrandingLayout.loader,
                action: ProgramBrandingLayout.action,
                children: [
                  {
                    index: true,
                    element: <Navigate to="colors" replace />,
                  },
                  {
                    path: "colors",
                    element: <ProgramBrandingColorsPage />,
                  },
                  {
                    path: "theme",
                    element: <ProgramBrandingThemePage />,
                  },
                  {
                    path: "assets",
                    element: <ProgramAssetsSettingPage />,
                  },
                  {
                    path: "fonts",
                    element: <ProgramFontsSettingPage />,
                  },
                  {
                    path: "borders",
                    element: <ProgramBordersSettingPage />,
                  },
                  {
                    path: "css",
                    element: <CustomCSSSetting />,
                  },
                ],
              },
              {
                path: "integrations",
                element: <IntegrationsSidebar />,
                children: [
                  {
                    index: true,
                    element: <Navigate to="social-network-pages" replace />,
                  },
                  {
                    path: "social-network-pages",
                    element: <IntegrationSocialNetworkPages />,
                  },
                  {
                    path: "offers-reward",
                    loader: IntegrationOffersReward.loader,
                    element: <IntegrationOffersReward />,
                  },
                  {
                    path: "photo-contests",
                    loader: IntegrationPhotoContests.loader,
                    element: <IntegrationPhotoContests />,
                  },
                  {
                    path: "aptos",
                    loader: IntegrationAptos.loader,
                    element: <IntegrationAptos />,
                  },
                  {
                    path: "api_auths",
                    children: [
                      {
                        index: true,
                        element: <IntegrationApiAuth />,
                      },
                      {
                        path: "new",
                        ...buildNewPage({
                          modelName: "API Key",
                          actionPath:
                            "/admin/v2/programs/:program_id/api_auths",
                          loaderPath:
                            "/admin/v2/programs/:program_id/api_auths/new",
                          redirectTo: false,
                        }),
                        element: <IntegrationApiAuthNew />,
                      },
                    ],
                  },
                  {
                    path: "webhooks",
                    children: [
                      {
                        index: true,
                        ...buildIndexPage({
                          modelName: "Webhook",
                          fetchPath: "/admin/v2/programs/:program_id/webhooks",
                          action: buildIndexPageActionButton({
                            link: `/admin/v2/programs/:program_id/settings/integrations/webhooks/new`,
                            text: "Add Webhook Endpoint",
                            setParams: true,
                          }),
                          searchKey: "url",
                        }),
                      },
                      {
                        path: "new",
                        ...buildNewPage({
                          modelName: "Webhook",
                          actionPath: "/admin/v2/programs/:program_id/webhooks",
                          loaderPath:
                            "/admin/v2/programs/:program_id/webhooks/new",
                          redirectTo: false,
                        }),
                      },
                      {
                        path: ":webhook_id",
                        children: [
                          {
                            index: true,
                            element: <Navigate to="./edit" />,
                          },
                          {
                            path: "edit",
                            ...buildEditPage({
                              modelName: "Webhook",
                              actionPath: "/admin/v2/webhooks/:webhook_id",
                              loaderPath: "/admin/v2/webhooks/:webhook_id/edit",
                              redirectTo: true,
                            }),
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
]);

const App = ({ id = "app", page = null }) => {
  const isServer = typeof window === "undefined";
  const el = isServer ? null : document.getElementById(id);
  const initialPage = page || JSON.parse(el.dataset.page);

  const { user, userPrograms, isAdmin, program, flash_messages } = initialPage;

  const csrfToken = ReactOnRails.authenticityToken();

  useEffect(() => {
    i18n.default.changeLanguage("en");
  }, []);

  return (
    <React.StrictMode>
      {/* @ts-ignore */}
      <I18nextProvider i18n={i18n.default}>
        <RailsAppProvider csrfToken={csrfToken}>
          <ThemeProvider defaultTheme="dark" storageKey="perk-ui-theme">
            <RailsFlashProvider messages={flash_messages}>
              <UserProvider
                user={user}
                programs={userPrograms}
                isAdmin={isAdmin}
              >
                <ProgramProvider
                  program={program || (userPrograms && userPrograms[0]) || {}}
                >
                  <RouterProvider router={router} />
                </ProgramProvider>
              </UserProvider>
            </RailsFlashProvider>
          </ThemeProvider>
        </RailsAppProvider>
      </I18nextProvider>
    </React.StrictMode>
  );
};

export default App;
