import { SectionTitle, SWRDataTable } from "@bleu/ui";
import { AddNewProgramDialog } from "@pages/admin/(components)/AddNewProgram";
import React from "react";
import { useLocation } from "react-router-dom";

function ProgramsPage() {
  const { hash } = useLocation();

  return (
    <>
      <div className="flex items-center justify-between space-y-2">
        <div>
          <SectionTitle>Programs</SectionTitle>
          <p className="text-muted-foreground">
            Here&apos;s a list of your programs.
          </p>
        </div>
      </div>
      <SWRDataTable
        fetchPath="/admin/v2/programs"
        action={
          <AddNewProgramDialog
            defaultOpen={hash === "#add-new-program"}
            description={
              "To continue, create your organization's first program."
            }
          />
        }
      />
    </>
  );
}
export default ProgramsPage;
