import { useToast } from "@bleu/ui";
import { useEffect } from "react";

export function useFormActionToast(actionData, intentToastTitle) {
  const { toast } = useToast();
  useEffect(() => {
    if (actionData?.success && actionData?.intent) {
      const title = intentToastTitle[actionData.intent];

      if (title) {
        const toastConfig = {
          title,
          description: actionData.data?.message ?? null,
          variant: "default",
        };

        toast(toastConfig);
      }
    } else if (actionData?.error) {
      toast({
        title: "Error",
        description: actionData?.error ?? "An error occurred",
        variant: "destructive",
      });
    }
  }, [actionData]);
}
