import { SWRDataTable } from "@bleu/ui";
import React from "react";
import { useParams } from "react-router-dom";

import { IntegrationConnect } from "./(components)/IntegrationConnect";

export default function IntegrationSocialNetworkPages() {
  const { program_id } = useParams();

  const fields = [
    {
      name: "url",
      type: "input",
      label: "Social Network Page URL",
      placeholder: "https://facebook.com/testing",
      required: true,
    },
    {
      name: "platform",
      type: "select",
      label: "Platforms",
      options: [
        { label: "Instagram", value: "instagram" },
        { label: "Facebook", value: "facebook" },
      ],
      required: true,
    },
  ];

  const url = `/admin/v2/programs/${program_id}/social_network_pages`;
  return (
    <>
      <div className="my-8 flex flex-col space-y-2">
        <IntegrationConnect
          defaultValues={{
            url: "",
          }}
          fields={fields}
          title={"Social Network Pages"}
          action={{
            path: url,
          }}
        />
        <SWRDataTable fetchPath={url} searchKey="url" />
      </div>
    </>
  );
}
