import { adminV2Dashboard } from "@api/all";
import {
  CardSkeleton,
  ChartSkeleton,
  KpiSkeleton,
  Separator,
  TableSkeleton,
} from "@bleu/ui";
import { client } from "@utils/api-client";
import React, { Suspense } from "react";
import { Await, defer, useLoaderData } from "react-router-dom";

import { DashboardKpi } from "../(components)/DashboardKpi";
import { DashboardLatestActions } from "../(components)/DashboardLatestActions";
import { DashboardLatestUsers } from "../(components)/DashboardLatestUsers";
import { DashboardTopUsers } from "../(components)/DashboardTopUsers";
import { DashboardUsersGrowthChart } from "../(components)/DashboardUsersGrowthChart";

interface LoaderData {
  programPerformanceMetrics: {
    data: {
      total_active_participants: number;
      recent_active_participants: number;
      total_completed_challenges: number;
      recent_completed_challenges: number;
      total_participant_actions: number;
      recent_participant_actions: number;
      total_program_visits: number;
      recent_program_visits: number;
    };
  };
  usersGrowth: {
    data: {
      x: string[];
      y: number[];
    };
  };
  topUsersAllTime: {
    total: number;
    filters: string[];
    data: {
      name: string;
      email: string;
      sign_in_count: number;
      points: number;
    }[];
    columns: {
      accessorKey: string;
      title: string;
      type: string;
      hide: boolean;
    }[];
  };
  topUsersThisMonth: {
    total: number;
    filters: string[];
    data: {
      name: string;
      email: string;
      sign_in_count: number;
      points: number;
    }[];
    columns: {
      accessorKey: string;
      title: string;
      type: string;
      hide: boolean;
    }[];
  };
  latestUsers: {
    data: {
      id: string;
      name: string;
      created_at: string;
      ahoy_city: string;
      ahoy_country: string;
    }[];
  };
  latestActions: {
    data: {
      action_type: string;
      details_url: string;
      name: string;
      actionable_url: string;
      title: string;
      created_at: string;
    }[];
  };
}

const headers = {
  Accept: "application/json",
};

const loader = async ({ params }) => {
  const { program_id } = params;
  const programPerformanceMetrics = client(
    adminV2Dashboard.programPerformanceMetrics.path({ program_id }),
    { headers },
  );

  const topUsersAllTime = client(
    adminV2Dashboard.topUsersAllTime.path({ program_id }),
    { headers },
  );

  const usersGrowth = client(
    adminV2Dashboard.usersGrowth.path({ program_id }),
    { headers },
  );

  const topUsersThisMonth = client(
    adminV2Dashboard.topUsersThisMonth.path({ program_id }),
    { headers },
  );
  const latestUsers = client(
    adminV2Dashboard.latestUsers.path({ program_id }),
    { headers },
  );

  const latestActions = client(
    adminV2Dashboard.latestActions.path({ program_id }),
    { headers },
  );

  return defer({
    programPerformanceMetrics,
    usersGrowth,
    topUsersAllTime,
    topUsersThisMonth,
    latestUsers,
    latestActions,
  });
};

function DashboardOverviewPage() {
  const {
    programPerformanceMetrics,
    usersGrowth,
    topUsersAllTime,
    topUsersThisMonth,
    latestUsers,
    latestActions,
  } = useLoaderData() as LoaderData;

  return (
    <>
      <Suspense
        fallback={
          <div className="flex gap-x-2">
            <KpiSkeleton />
            <KpiSkeleton />
            <KpiSkeleton />
            <KpiSkeleton />
          </div>
        }
      >
        <Await
          resolve={programPerformanceMetrics}
          errorElement={<p>Error! something went wrong</p>}
        >
          {(data) => {
            return <DashboardKpi programPerformanceMetrics={data.data} />;
          }}
        </Await>
      </Suspense>
      <div className="flex gap-x-4">
        <div className="w-1/2">
          <div className="flex flex-col gap-y-2">
            <div className="flex items-center gap-x-2">
              <h2 className="text-2xl font-bold whitespace-nowrap">
                Users Growth
              </h2>
              <div className="grow">
                <Separator />
              </div>
            </div>
            <Suspense
              fallback={
                <ChartSkeleton
                  className="border rounded-md max-w-fit overflow-hidden"
                  barsSetCount={3}
                />
              }
            >
              <Await
                resolve={usersGrowth}
                errorElement={<p>Error! something went wrong</p>}
              >
                {(data) => {
                  return <DashboardUsersGrowthChart usersGrowth={data.data} />;
                }}
              </Await>
            </Suspense>
          </div>
        </div>
        <div className="w-1/2">
          <Suspense
            fallback={
              <div className="flex gap-x-2 h-max">
                {Array.from({ length: 3 }, (_, index) => (
                  <CardSkeleton
                    key={index}
                    className="h-[300px] flex flex-col w-full justify-between shadow-none border"
                  />
                ))}
              </div>
            }
          >
            <Await
              resolve={latestUsers}
              errorElement={<p>Error! something went wrong</p>}
            >
              {(data) => {
                return <DashboardLatestUsers data={data.data} />;
              }}
            </Await>
          </Suspense>
        </div>
      </div>
      <div className="w-full">
        <Suspense
          fallback={
            <div className="flex gap-x-2 h-max mt-10">
              {Array.from({ length: 6 }, (_, index) => (
                <CardSkeleton
                  key={index}
                  className="size-full flex flex-col w-full justify-between shadow-none border"
                />
              ))}
            </div>
          }
        >
          <Await
            resolve={latestActions}
            errorElement={<p>Error! something went wrong</p>}
          >
            {(data) => {
              return <DashboardLatestActions data={data.data} />;
            }}
          </Await>
        </Suspense>
      </div>
      <div className="grid grid-cols-2 gap-5 mb-5">
        <div className="col-span-1">
          <Suspense
            fallback={
              <TableSkeleton className="border-2 rounded-2xl animate-pulse" />
            }
          >
            <Await
              resolve={topUsersThisMonth}
              errorElement={<p>Error! something went wrong</p>}
            >
              {(data) => {
                return (
                  <DashboardTopUsers
                    data={data}
                    title="Top Users From Last 30 days"
                  />
                );
              }}
            </Await>
          </Suspense>
        </div>
        <div className="col-span-1">
          <Suspense
            fallback={
              <TableSkeleton className="border-2 rounded-2xl animate-pulse" />
            }
          >
            <Await
              resolve={topUsersAllTime}
              errorElement={<p>Error! something went wrong</p>}
            >
              {(data) => {
                return (
                  <DashboardTopUsers data={data} title="Top Users All Time" />
                );
              }}
            </Await>
          </Suspense>
        </div>
      </div>
    </>
  );
}

DashboardOverviewPage.loader = loader;
export default DashboardOverviewPage;
