import Button from "@components/ui/Button";
import React from "react";

export default function MembershipBanner({ challenge }) {
  // @ts-expect-error TS(2304) FIXME: Cannot find name 'ReactOnRails'.
  const csrfToken = ReactOnRails.authenticityToken();

  return (
    <div className="mb-4 bg-gradient-to-l from-[#313131] to-black">
      <div className="px-6 py-12 sm:px-6 sm:py-12 lg:px-8">
        <div className="mx-auto text-center">
          <div
            className="text-perk-primary-content mx-auto mt-2 max-w-3xl text-lg leading-8"
            dangerouslySetInnerHTML={{ __html: challenge.description }}
          ></div>
          <div className="mt-10 flex items-center justify-center gap-x-6">
            {challenge.membership_challenge.price > 0 ? (
              <>
                <form
                  action={`/participants/challenges/${challenge.id}/membership_checkout_session`}
                  method="post"
                >
                  <input
                    type="hidden"
                    name="authenticity_token"
                    value={csrfToken}
                  />
                  {/* @ts-expect-error TS(2322) FIXME: Type '{ children: string; className: string; type:... Remove this comment to see the full error message */}
                  <Button className="border-0" type="submit">
                    Become a member
                  </Button>
                </form>
                <button
                  // @ts-expect-error TS(2322) FIXME: Type 'string' is not assignable to type '(string |... Remove this comment to see the full error message
                  onClick={() => (window.location = "/my-offers")}
                  className="text-sm font-semibold leading-6 text-white hover:underline"
                >
                  Or check offers first <span aria-hidden="true">→</span>
                </button>
              </>
            ) : (
              <button
                // @ts-expect-error TS(2322) FIXME: Type 'string' is not assignable to type '(string |... Remove this comment to see the full error message
                onClick={() => (window.location = "/my-offers")}
                className="text-sm font-semibold leading-6 text-white hover:underline"
              >
                Check offers
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
