import { Button } from "@bleu/ui";
import Card from "@components/ui/Card";
import { Cross1Icon } from "@radix-ui/react-icons";
import React from "react";

export function MagicLinkSent({ participant }) {
  return (
    <Card.Root className="w-full max-w-none bg-white border pb-6 !rounded-perk-cards">
      <Card.Header>
        <div className="flex w-full justify-end">
          <Button
            className="bg-transparent shadow-none w-fit p-2 outline-perk-primary"
            onClick={() => {
              window.location.href = "/";
            }}
          >
            <Cross1Icon className="size-3" />
          </Button>
        </div>
        <Card.Title className="pl-0 text-xl text-center">
          Thanks, {participant?.first_name}!
        </Card.Title>
      </Card.Header>

      <Card.Content className="mx-4">
        A login link is in your email. Click it within 20 minutes to access your
        account.
      </Card.Content>
    </Card.Root>
  );
}
