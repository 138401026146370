import { SelectInput } from "@bleu/ui";
import Button from "@components/ui/Button";
import { cn } from "@utils/mergeClassNames";
import React, { useState } from "react";

export function DonationForm({
  entities,
  amounts,
  challengeId,
  pointsLabel,
  entityLabel,
  className = "",
}) {
  const [selectedEntity, setSelectedEntity] = useState("");
  const [selectedAmount, setSelectedAmount] = useState("");

  // @ts-expect-error TS(2304) FIXME: Cannot find name 'ReactOnRails'.
  const csrfToken = ReactOnRails.authenticityToken();

  return (
    <form
      className={cn(
        "relative w-full max-w-xl rounded bg-gray-50 p-7 shadow-2xl sm:p-10 xl:w-5/12 xl:px-8",
        className,
      )}
      action={`/participants/challenges/${challengeId}/donation_checkout_session`}
      method="post"
    >
      <input type="hidden" name="authenticity_token" value={csrfToken} />
      <div className="mt-2 flex flex-col justify-start gap-6 text-center sm:mt-0 sm:text-left">
        <SelectInput
          label={`Select the ${entityLabel}`}
          name="entity"
          onValueChange={setSelectedEntity}
          options={entities.map((entity) => ({
            id: entity.id,
            value: entity.entity,
          }))}
        />
        <SelectInput
          label="Select a value"
          name="amount"
          onValueChange={setSelectedAmount}
          options={amounts.map((amount) => ({
            id: amount.id,
            value: `Donate $${amount.amount} and earn ${amount.points} ${pointsLabel}`,
          }))}
        />
      </div>
      {/* @ts-expect-error TS(2322) FIXME: Type '{ children: string; disabled: boolean; class... Remove this comment to see the full error message */}
      <Button
        disabled={!selectedEntity || !selectedAmount}
        className="mt-5 w-full sm:mt-6"
        type="submit"
      >
        Continue
      </Button>
    </form>
  );
}

export default function DonationBanner({ challenge }) {
  const {
    donation_challenge: {
      donation_challenge_amount_options: amountOptions,
      donation_challenge_entity_options: entityOptions,
      entity_label,
    },
    program: { term_points_as = "points" },
    caption,
    description,
    id,
  } = challenge;

  return (
    <div className="mb-4 overflow-hidden bg-gray-900">
      <div className="mx-auto px-4 py-16 sm:max-w-xl md:max-w-full md:px-24 lg:max-w-screen-xl lg:px-8 lg:py-20">
        <div className="flex flex-col items-center justify-between xl:flex-row">
          <div className="mb-12 w-full max-w-xl xl:mb-0 xl:w-7/12 xl:pr-16">
            <h2 className="text-perk-old-accent mb-6 max-w-lg font-sans text-3xl font-bold tracking-tight sm:text-4xl sm:leading-none">
              {caption}
            </h2>
            <div
              className="mb-4 max-w-xl text-base text-gray-400 md:text-lg"
              dangerouslySetInnerHTML={{ __html: description }}
            ></div>
          </div>
          <DonationForm
            entities={entityOptions}
            amounts={amountOptions}
            challengeId={id}
            pointsLabel={term_points_as}
            entityLabel={entity_label}
          />
        </div>
      </div>
    </div>
  );
}
