import {
  Avatar,
  AvatarFallback,
  AvatarImage,
  Button,
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandSeparator,
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@bleu/ui";
import Dialog from "@components/ui/Dialog";
import {
  CaretSortIcon,
  CheckIcon,
  PlusCircledIcon,
} from "@radix-ui/react-icons";
import { cn } from "@utils/mergeClassNames";
import * as React from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { AddNewProgramForm } from "./AddNewProgram";

export function ProgramSwitcher({ className, program, userPrograms }) {
  const navigate = useNavigate();
  const location = useLocation();
  const groups = React.useMemo(() => {
    const team = program
      ? { label: program.name, value: program.id }
      : { label: userPrograms[0].name, value: userPrograms[0].id };

    const current = {
      label: "Current Program",
      teams: [team],
    };

    const other = {
      label: "Programs",
      teams: userPrograms
        .filter((c) => c.id !== current.teams[0].value)
        .map((c) => ({
          label: c.name,
          value: c.id,
        })),
    };

    return [current, other];
  }, [program, userPrograms]);

  const [open, setOpen] = React.useState(false);
  const [showNewTeamDialog, setShowNewTeamDialog] = React.useState(false);
  const selectedTeam = groups[0].teams[0];

  const redirectToDashboard = (id) => {
    const currentPath = location.pathname;
    const newPath = currentPath.startsWith("/admin/v2")
      ? `/admin/v2/programs/${id}/dashboard`
      : `programs/${id}/dashboard`;
    window.location.href = newPath;
  };

  return (
    // @ts-expect-error TS(2322) FIXME: Type '{ children: Element[]; open: boolean; onOpen... Remove this comment to see the full error message
    <Dialog.Root open={showNewTeamDialog} onOpenChange={setShowNewTeamDialog}>
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <Button
            variant="outline"
            role="combobox"
            aria-expanded={open}
            aria-label="Select a program"
            className={cn(
              "w-[200px] justify-between dark:border-2 text-sm",
              className,
            )}
          >
            <Avatar className="mr-2 size-5">
              <AvatarImage
                src={`https://avatar.vercel.sh/${selectedTeam.value}.png`}
                alt={selectedTeam.label}
              />
              <AvatarFallback>SC</AvatarFallback>
            </Avatar>
            <p className="truncate">{selectedTeam.label}</p>
            <CaretSortIcon className="ml-auto size-4 shrink-0 opacity-50" />
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-[200px] p-0">
          <Command>
            <CommandList>
              <CommandInput placeholder="Search program..." />
              <CommandEmpty>No program found.</CommandEmpty>
              {groups.map((group) => (
                <CommandGroup key={group.label} heading={group.label}>
                  {group.teams.map((program_item) => (
                    <CommandItem
                      key={program_item.value}
                      onSelect={() => {
                        redirectToDashboard(program_item.value);
                        setOpen(false);
                      }}
                      className="text-sm"
                    >
                      <Avatar className="mr-2 size-5">
                        <AvatarImage
                          src={`https://avatar.vercel.sh/${program_item.value}.png`}
                          alt={program_item.label}
                          className="grayscale"
                        />
                        <AvatarFallback>SC</AvatarFallback>
                      </Avatar>
                      {program_item.label}
                      <CheckIcon
                        className={cn(
                          "ml-auto h-4 w-4",
                          selectedTeam.value === program_item.value
                            ? "opacity-100"
                            : "opacity-0",
                        )}
                      />
                    </CommandItem>
                  ))}
                </CommandGroup>
              ))}
            </CommandList>
            <CommandSeparator />
            <CommandList>
              <CommandGroup>
                <Dialog.Trigger asChild>
                  <CommandItem
                    onSelect={() => {
                      setOpen(false);
                      setShowNewTeamDialog(true);
                    }}
                  >
                    <PlusCircledIcon className="mr-2 size-5" />
                    Create Program
                  </CommandItem>
                </Dialog.Trigger>
              </CommandGroup>
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
      <Dialog.Content className="sm:max-w-[550px]">
        <AddNewProgramForm
          description={"Add a new program for your organization."}
        />
      </Dialog.Content>
    </Dialog.Root>
  );
}
