import { ClockIcon } from "@radix-ui/react-icons";
import { distanceOfTimeInWords } from "@utils/formatDate";
import React from "react";

import { ChallengeData } from "./types";

export function TimeUntilEnd({ data }: { data: ChallengeData }) {
  return (
    <div className="flex items-center gap-x-1">
      <ClockIcon className="size-3" />
      <p className="m-0">{distanceOfTimeInWords(new Date(), data.finish)}</p>
    </div>
  );
}
