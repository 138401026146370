import { useToast } from "@bleu/ui";
import { usePrevious } from "@hooks/usePrevious";
import { getButtonText } from "@pages/admin/challenges/(utils)/CtaButtonText";
import { buildForm } from "@utils/buildForm";
import { getObjectsDifference } from "@utils/objectDifference";
import { serialize } from "object-to-formdata";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useSubmit } from "react-router-dom";
import { Wizard } from "react-use-wizard";

import { Step } from "./Step";
import { WizardFormWrapper } from "./Wrapper";

export const WizardForm = ({ data, mode = "create" }) => {
  const steps = data.steps;
  const defaultValues = data.defaultValues;
  const submit = useSubmit();
  const { toast } = useToast();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const form = useForm<any>({
    mode: "all",
    defaultValues,
  });

  const challengeType = form.watch("challenge_type");
  const parameters = form.watch("parameters");

  const previousParameters = usePrevious(parameters);

  useEffect(() => {
    if (challengeType && mode === "create") {
      const buttonText = getButtonText(challengeType, parameters);
      form.setValue("cta_button_text", buttonText);
    }
    if (
      mode === "update" &&
      previousParameters &&
      previousParameters !== parameters
    ) {
      const buttonText = getButtonText(challengeType, parameters);
      form.setValue("cta_button_text", buttonText);
    }
  }, [challengeType, parameters]);

  const handleSubmit = async () => {
    const isValid = await form.trigger();
    if (!isValid) return;

    // if the mode is "update", we only send the changed values
    const values =
      mode === "create"
        ? form.getValues()
        : getObjectsDifference(defaultValues, form.getValues());

    if (Object.keys(values).length === 0) {
      toast({
        title: "No changes detected",
        description: "No changes were detected. Please try again.",
        variant: "destructive",
      });
      return;
    }
    const formData = serialize(values);

    submit(formData, { method: "post", encType: "multipart/form-data" });
  };
  return (
    <div className="flex w-full items-center justify-center py-5 flex-col px-16">
      <Wizard
        wrapper={
          // @ts-expect-error TS(2741) FIXME: Property 'children' is missing in type '{ steps: a... Remove this comment to see the full error message
          <WizardFormWrapper steps={steps} form={form} />
        }
        startIndex={0}
      >
        {steps.map((step, idx) => {
          return (
            <Step key={idx} handleSubmit={handleSubmit} mode={mode} form={form}>
              <div className="flex flex-wrap gap-5 p-5">
                {buildForm(step.fields, form)}
              </div>
            </Step>
          );
        })}
      </Wizard>
    </div>
  );
};
